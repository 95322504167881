import React from "react";

export default function ConvertorS3({ formikValues, setFieldValue, error, touched, measurement_unit }) {
   const handleConvertorChange = e => {
      const { name, value } = e.target;
      if (name === "monthly_consumption_gas") {
         setFieldValue("monthly_consumption_gas", value);
         setFieldValue("monthly_consumption_gas_mc", (value * 0.094).toFixed(2));
      }
      if (name === "monthly_consumption_gas_mc") {
         setFieldValue("monthly_consumption_gas_mc", value);
         setFieldValue("monthly_consumption_gas", (value * 0.094).toFixed(2));
      }
   };

   const handleOnKeyDown = e => {
      const allowedCharacters = /^[0-9.]+$/;
      if (!allowedCharacters.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
         e.preventDefault();
      }
   };

   return (
      <>
         <div className="convertor-container">
            <div>
               <label className="custom-label-title mt-2 mb-0">Gaz {measurement_unit}</label>

               <div className="text-input-wrapper">
                  <input
                     autoComplete="off"
                     className="text-input-input"
                     type={"number"}
                     id={"monthly_consumption_gas"}
                     name={"monthly_consumption_gas"}
                     placeholder={"kWh"}
                     value={formikValues.monthly_consumption_gas}
                     onChange={handleConvertorChange}
                     onKeyDown={handleOnKeyDown}
                  />
                  <label className="text-input-label" htmlFor={"monthly_consumption_gas"}>
                     {measurement_unit}
                  </label>
               </div>
               <p className="description-under-input">* 1 kWh = 0.09mc</p>
            </div>
            <p className="equals-sign">=</p>
            <div>
               <label className="custom-label-title mt-2 mb-0">Gaz mc</label>
               <div className="text-input-wrapper">
                  <input
                     autoComplete="off"
                     className="text-input-input"
                     type={"number"}
                     id={"monthly_consumption_gas_mc"}
                     name={"monthly_consumption_gas_mc"}
                     placeholder={"mc"}
                     value={formikValues.monthly_consumption_gas_mc}
                     onChange={e => handleConvertorChange(e)}
                     onKeyDown={handleOnKeyDown}
                  />
                  <label className="text-input-label" htmlFor={"monthly_consumption_gas_mc"}>
                     mc
                  </label>
               </div>
               <p className="description-under-input">* 1 kWh = 0.09mc</p>
            </div>
         </div>
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
