import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";

export default function ModalSignature({ signatureValue, setFieldValue, setIsModalSignatureOpen }) {
   let signPad = useRef({});

   const saveSignature = () => {
      if (signatureValue || signPad?.current?.isEmpty()) {
         const message = signatureValue ? "Există deja o semnătură!" : "Trebuie să semnezi în chenar!";
         toast.info(message);
         return;
      }
      const signatureData = signPad.current.toDataURL();
      setFieldValue("signature", signatureData);
      toast.success("Semnătura a fost salvată!");
      setIsModalSignatureOpen(false);
   };

   const clearSignature = () => {
      if (signatureValue) setFieldValue("signature", "");
      if (signPad?.current && !signatureValue) signPad.current.clear();
   };

   return (
      <>
         {ReactDOM.createPortal(
            <div className="modal-declaratie-container">
               <div className="modal-signature-content">
                  <button type="button" className="modal-declaratie-close-button" onClick={() => setIsModalSignatureOpen(false)}>
                     x
                  </button>
                  <h2 className="modal-declaratie-title">Semnează in chenarul de mai jos</h2>
                  {signatureValue ? (
                     <img className="signature-img" src={signatureValue} alt="signature" />
                  ) : (
                     <SignatureCanvas ref={signPad} canvasProps={{ className: "signCanvas" }} />
                  )}
                  <div className="signature-buttons-container">
                     <button onClick={() => clearSignature()} type="button" className="signature-button text-gray">
                        Șterge semnătura
                     </button>
                     <button onClick={() => saveSignature()} type="button" className="signature-button text-red">
                        Salvează semnătura
                     </button>
                  </div>
               </div>
            </div>,
            document.getElementById("modal-declaratie"),
         )}
      </>
   );
}
