const getTipFurnizare = () => {
   const sessionStepState = sessionStorage.getItem("sessionStepsState");
   const parsedSessionStepState = JSON.parse(sessionStepState);
   const tipFurnizare = parsedSessionStepState?.step1.utility_type;

   return tipFurnizare;
};

const getTipPersoana = () => {
   const sessionStepState = sessionStorage.getItem("sessionStepsState");
   const parsedSessionStepState = JSON.parse(sessionStepState);
   const tipPersoana = parsedSessionStepState?.step1.consumer_type;

   return tipPersoana;
};
const getStateStep6 = () => {
   const sessionStepState = sessionStorage.getItem("sessionStepsState");
   const parsedSessionStepState = JSON.parse(sessionStepState);
   const step6State = parsedSessionStepState?.step6;

   return step6State;
};

const estePFCuAsociatie = () => {
   const sessionStepState = sessionStorage.getItem("sessionStepsState");
   const parsedSessionStepState = JSON.parse(sessionStepState);
   const estePFCuAsociatie = parsedSessionStepState?.step1.tenant_assoc;
   return parseInt(estePFCuAsociatie);
};

export const storageHelpers = {
   getTipFurnizare,
   getTipPersoana,
   estePFCuAsociatie,
   getStateStep6,
};
