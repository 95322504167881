import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { goToTop } from "helpers/helpers";
import { functionHelpers } from "helpers/functionHelpers";
import { apiSteps } from "api/steps.service";
import { apiNomenclature } from "api/nomenclature.service";
import { step3ValidationSchema } from "schemas/validationSchema";
import objectsSchema from "schemas/objectsSchema";
import NavigateButtons from "components/NavigateButtons";
import StepHeaderS3 from "components/Step3/StepHeaderS3";
import ConvertorS3 from "components/Step3/ConvertorS3";
import CustomSelect from "components/inputs/CustomSelect";
import TextInput from "components/inputs/TextInput";
import { isArray, isNumber } from "lodash";
import { toast } from "react-toastify";

export default function Step3({ currentStep, setCurrentStep, setLoading, stepsState, persistState, firstSubmit, setFirstSubmit }) {
   const { utility_type, consumer_type, county_id, tenant_assoc } = stepsState;
   const [optionsProvidersGas, setOptionsProvidersGas] = useState([]);
   const [optionsProvidersEnergy, setOptionsProvidersEnergy] = useState([]);
   // const modifiedOptionsTensiune = consumer_type !== "pj" ? objectsSchema.optionsTensiune.slice(0, -1) : objectsSchema.optionsTensiune;

   const formik = useFormik({
      enableReinitialize: true,
      validateOnChange: firstSubmit,
      validateOnBlur: false,
      initialValues: {
         ...stepsState.step3,
         estimated_energy:
            consumer_type === "pf"
               ? functionHelpers.getValueForInputEnergieElectrica(stepsState.step3.estimated_energy)
               : functionHelpers.getValueForInputEnergieElectrica(stepsState.step3.estimated_energy) / 1000,
         estimated_gas:
            consumer_type === "pf"
               ? functionHelpers.getValueForInputGaz(stepsState.step3.estimated_gas)
               : functionHelpers.getValueForInputGaz(stepsState.step3.estimated_gas) / 1000,
      },
      validationSchema: step3ValidationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         goToTop();
         setLoading(true);
         await saveData(values);
         setLoading(false);
         setSubmitting(false);
      },
   });

   const handleTipCasaSelectChange = value => {
      formik.setFieldValue("surface_area", value.value);
      const isPF = consumer_type !== "pj";

      formik.setFieldValue(
         "estimated_energy",
         isPF ? functionHelpers.getValueForInputEnergieElectrica(value.value) : functionHelpers.getValueForInputEnergieElectrica(value.value) / 1000,
      );
      formik.setFieldValue(
         "estimated_gas",
         isPF ? functionHelpers.getValueForInputGaz(value.value) : functionHelpers.getValueForInputGaz(value.value) / 1000,
      );
   };

   const formSubmition = e => {
      e.preventDefault();
      setFirstSubmit(true);
      formik.handleSubmit();
   };

   const saveData = async values => {
      //If the consumption is bigger then 290kWh, we show error message)

      console.log("🚀 ~ saveData ~ values:", values);

      console.log("🚀 ~ saveData ~ monthly_consumption_energy:", values?.monthly_consumption_gas);
      if (
         (isNumber(values?.monthly_consumption_energy) && values?.monthly_consumption_energy > 290 && consumer_type === "pj") ||
         (isNumber(values?.monthly_consumption_gas) && values?.monthly_consumption_gas > 290 * 1000 && consumer_type === "pj")
      ) {
         toast.error("Pentru un consum mai mare de 290mWh, vă rugăm să vă contactați serviciul nostru de asistență.");
         return;
      }
      const requestPayload = {
         ...values,
         utility_type,
         consumer_type,
         tenant_assoc,
      };
      // toast.error(response.error);
      const response = await apiSteps.getOffers(requestPayload, "STEP4");

      if (response.isSuccess) {
         persistState(prevState => {
            return {
               ...prevState,
               step3: {
                  ...prevState.step3,
                  surface_area: values.surface_area,
                  estimated_energy: values.estimated_energy,
                  estimated_gas: values.estimated_gas,
                  monthly_consumption_energy: values.monthly_consumption_energy,
                  monthly_consumption_gas: values.monthly_consumption_gas,
                  monthly_consumption_gas_mc: values.monthly_consumption_gas_mc,
                  provider_id_gas: values.provider_id_gas,
                  provider_id_energy: values.provider_id_energy,
               },
               step4: {
                  ...prevState.step4,
                  offers: response.response.data,
               },
               step5: {
                  ...prevState.step5,
                  voltage: values.voltage,
                  consumption_agreement_gas: functionHelpers.getValueForConsumptionAgreement(
                     values.monthly_consumption_gas !== "" ? values.monthly_consumption_gas : values.estimated_gas,
                  ),
                  consumption_agreement_energy: functionHelpers.getValueForConsumptionAgreement(
                     values.monthly_consumption_energy !== "" ? values.monthly_consumption_energy : values.estimated_energy,
                  ),
                  locations:
                     prevState.step5.isInit === true
                        ? prevState.step5.locations
                        : [
                             {
                                ...prevState.step5.locations[0], // Spread the properties of the object at index 0
                                isInit: false,
                                switchGas: prevState.step1.consumer_type === "pj",
                                switchEnergy: prevState.step1.consumer_type === "pj",
                                voltage: values.voltage, // Modify the properties as required
                                consumption_agreement_gas: functionHelpers.getValueForConsumptionAgreement(
                                   values.monthly_consumption_gas !== "" ? values.monthly_consumption_gas : values.estimated_gas,
                                ),
                                consumption_agreement_energy: functionHelpers.getValueForConsumptionAgreement(
                                   values.monthly_consumption_energy !== "" ? values.monthly_consumption_energy : values.estimated_energy,
                                ),
                             },
                             ...prevState.step5.locations.slice(1), // Spread the remaining objects in the array
                          ],
               },
            };
         });
         setCurrentStep(currentStep + 1);
         sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
         setFirstSubmit(false);
      }
   };

   const getOptionsProviders = async () => {
      setLoading(true);
      const response = await apiNomenclature.getProviders(utility_type, county_id);
      setLoading(false);

      if (utility_type === "GAZ_SI_CURENT") {
         if (!isArray(response?.data?.gas) || !isArray(response?.data?.energy)) {
            return;
         }
         const gasOptions = response.data.gas.map(item => ({ value: item.provider_id, label: item.provider_name }));
         const energyOptions = response.data.energy.map(item => ({ value: item.provider_id, label: item.provider_name }));
         setOptionsProvidersGas(gasOptions);
         setOptionsProvidersEnergy(energyOptions);

         // If the provider_id_gas and provider_id_energy have not been set, set them to the first value
         if (!formik.values.provider_id_gas) {
            formik.setFieldValue("provider_id_gas", gasOptions[0]?.value);
         }
         if (!formik.values.provider_id_energy) {
            formik.setFieldValue("provider_id_energy", energyOptions[0]?.value);
         }
      }

      if (utility_type === "CURENT") {
         if (!isArray(response?.data?.energy)) {
            return;
         }
         const energyOptions = response.data.energy.map(item => ({ value: item.provider_id, label: item.provider_name }));
         setOptionsProvidersEnergy(energyOptions);
         if (!formik.values.provider_id_energy) {
            formik.setFieldValue("provider_id_energy", energyOptions[0]?.value);
         }
      }

      if (utility_type === "GAZ") {
         if (!isArray(response?.data?.gas)) {
            return;
         }
         const gasOptions = response.data.gas.map(item => ({ value: item.provider_id, label: item.provider_name }));
         setOptionsProvidersGas(gasOptions);
         if (!formik.values.provider_id_gas) {
            formik.setFieldValue("provider_id_gas", gasOptions[0]?.value);
         }
      }
   };

   useEffect(() => {
      getOptionsProviders();
   }, [utility_type, county_id]);

   useEffect(() => {
      if (optionsProvidersGas.length > 0) {
         formik.setFieldValue("provider_id_gas", optionsProvidersGas[0].value);
      }

      if (optionsProvidersEnergy.length > 0) {
         formik.setFieldValue("provider_id_energy", optionsProvidersEnergy[0].value);
      }
   }, [optionsProvidersGas, optionsProvidersEnergy]);

   const measurement_unit = consumer_type !== "pj" ? "kWh" : "MWh";
   return (
      <section className="pb-55">
         <div className="container">
            <div className="col-md-9 mx-auto">
               <h2 className="step-title">Informații despre consum</h2>
               <h3 className="step-description col-md-9 mx-auto">
                  Spune-ne cât consumi lunar - în medie sau într-o lună, conform datelor dintr-o factură actuală. Dacă nu ai o factură la îndemână, ne
                  poți indica suprafața locuinței și facem noi o estimare de consum mediu lunar.
               </h3>
               <form onSubmit={formSubmition}>
                  <div className="step3-conainer">
                     <div className="step-3-left">
                        <StepHeaderS3 title={"Consum Lunar"} />

                        {(utility_type === "CURENT" || utility_type === "GAZ_SI_CURENT") && (
                           <>
                              <label className="custom-label-title mt-2 mb-0">Energie electrica</label>
                              <TextInput
                                 label={`(${measurement_unit})`}
                                 type={"text"}
                                 allowNumbersAndDots={true}
                                 name={"monthly_consumption_energy"}
                                 setFieldValue={formik.setFieldValue}
                                 value={formik.values.monthly_consumption_energy}
                                 error={formik.errors.monthly_consumption_energy}
                                 touched={formik.touched.monthly_consumption_energy}
                                 parentClassName={"col-md-12 mt-2"}
                                 descriptionUnderInput={"* 1 MWh = 1000kWh"}
                              />

                              {/*<CustomSelect*/}
                              {/*   name={"voltage"}*/}
                              {/*   options={modifiedOptionsTensiune}*/}
                              {/*   value={formik.values.voltage}*/}
                              {/*   onChange={value => formik.setFieldValue("voltage", value.value)}*/}
                              {/*   error={formik.errors.voltage}*/}
                              {/*   touched={formik.touched.voltage}*/}
                              {/*   label={"Nivel Tensiune"}*/}
                              {/*   labelClassName={"mt-2 mb-0"}*/}
                              {/*   parentClassName={"col-md-12"}*/}
                              {/*/>*/}
                           </>
                        )}

                        {utility_type === "CURENT" && (
                           <div className="mt-50 w-100 relative">
                              <CustomSelect
                                 options={optionsProvidersEnergy}
                                 value={formik.values.provider_id_energy}
                                 onChange={value => formik.setFieldValue("provider_id_energy", value.value)}
                                 placeholder="Alege distribuitor..."
                                 name={"provider_id_energy"}
                                 label={"Distribuitor de electricitate"}
                                 error={formik.errors.provider_id_energy}
                                 touched={formik.touched.provider_id_energy}
                                 labelClassName={"label-select"}
                              />
                           </div>
                        )}

                        {(utility_type === "GAZ" || utility_type === "GAZ_SI_CURENT") && (
                           <div style={{ marginTop: 7 }}>
                              <ConvertorS3 measurement_unit={measurement_unit} formikValues={formik.values} setFieldValue={formik.setFieldValue} />
                           </div>
                        )}
                        {utility_type === "GAZ" && (
                           <div className="w-100 relative" style={{ marginTop: 32 }}>
                              <CustomSelect
                                 options={optionsProvidersGas}
                                 value={formik.values.provider_id_gas}
                                 onChange={value => formik.setFieldValue("provider_id_gas", value.value)}
                                 placeholder="Alege distribuitor..."
                                 name={"provider_id_gas"}
                                 label={"Distribuitor de gaz"}
                                 error={formik.errors.provider_id_gas}
                                 touched={formik.touched.provider_id_gas}
                                 labelClassName={"label-select"}
                              />
                           </div>
                        )}
                     </div>
                     <div className="step-3-right">
                        <StepHeaderS3 title={"Tip Locuinta"} />

                        <CustomSelect
                           name={"surface_area"}
                           options={objectsSchema.optionsTipLocuinta}
                           value={formik.values.surface_area}
                           onChange={value => handleTipCasaSelectChange(value)}
                           error={formik.errors.surface_area}
                           touched={formik.touched.surface_area}
                           parentClassName={"col-md-12 mt-lg-5"}
                           styleParent={{
                              paddingTop: 9,
                           }}
                        />
                        <div>
                           {(utility_type === "CURENT" || utility_type === "GAZ_SI_CURENT") && (
                              <div className="read-only-container mt-lg-3" style={{ paddingTop: 3 }}>
                                 <label className="custom-label-title mt-2 mb-0">Energie electrica</label>
                                 <input className="text-input-input" value={formik.values.estimated_energy} readOnly />
                              </div>
                           )}
                        </div>

                        {(utility_type === "GAZ" || utility_type === "GAZ_SI_CURENT") && (
                           <div className="read-only-container">
                              <label className="custom-label-title mt-2 mb-0">Gaz {measurement_unit}</label>
                              <input className="text-input-input" value={formik.values.estimated_gas} readOnly />
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="row mt-25">
                     {utility_type === "GAZ_SI_CURENT" && (
                        <CustomSelect
                           options={optionsProvidersEnergy}
                           value={formik.values.provider_id_energy}
                           onChange={value => formik.setFieldValue("provider_id_energy", value.value)}
                           placeholder="Alege distribuitor..."
                           name={"provider_id_energy"}
                           label={"Distribuitor de electricitate"}
                           error={formik.errors.provider_id_energy}
                           touched={formik.touched.provider_id_energy}
                           parentClassName={"col-md-6 relative"}
                           labelClassName={"label-select"}
                        />
                     )}
                     {utility_type === "GAZ_SI_CURENT" && (
                        <CustomSelect
                           options={optionsProvidersGas}
                           value={formik.values.provider_id_gas}
                           onChange={value => formik.setFieldValue("provider_id_gas", value.value)}
                           placeholder="Alege distribuitor..."
                           name={"provider_id_gas"}
                           label={"Distribuitor de gaz"}
                           error={formik.errors.provider_id_gas}
                           touched={formik.touched.provider_id_gas}
                           parentClassName={"col-md-6 relative input-row"}
                           labelClassName={"label-select"}
                        />
                     )}
                  </div>

                  <NavigateButtons
                     isNextDisabled={formik.isSubmitting}
                     formikErrors={formik.errors}
                     currentStep={currentStep}
                     setCurrentStep={setCurrentStep}
                  />
               </form>
            </div>
         </div>
      </section>
   );
}
