import { requestJson, RequestMethod } from "helpers/httpHelper";
import prepareData from "schemas/prepareDataSchema";

const getCounties = async () => {
   const response = await requestJson("nomenclature/counties", RequestMethod.GET, {});
   if (response.isSuccess) return response.response;
   //    else toast.error(response.error);
};

const getCitiesByCounty = async county_id => {
   const response = await requestJson("nomenclature/cities-by-county", RequestMethod.GET, {
      queryStringParams: { county_id },
   });

   return response;
};

const getStreetsByCity = async city_id => {
   const response = await requestJson("nomenclature/streets-by-city", RequestMethod.GET, {
      queryStringParams: { city_id },
   });
   if (response.isSuccess) return response.response;
   //    else toast.error(response.error);
};

const getPostalCodesByStreet = async street_id => {
   const response = await requestJson("nomenclature/postal-codes-by-street", RequestMethod.GET, {
      queryStringParams: { street_id },
   });
   if (response.isSuccess) return response.response;
   //    else toast.error(response.error);
};

const getProviders = async (utility_type, county_id) => {
   const response = await requestJson("nomenclature/providers", RequestMethod.POST, {
      data: prepareData.getProviders(utility_type, county_id),
   });
   if (response.isSuccess) return response.response;
   //    else toast.error(response.error);
};

const getBanks = async () => {
   const response = await requestJson("nomenclature/banks", RequestMethod.GET, {});
   if (response.isSuccess) return response.response;
   //    else toast.error(response.error);
};

export const apiNomenclature = {
   getCounties,
   getCitiesByCounty,
   getStreetsByCity,
   getPostalCodesByStreet,
   getProviders,
   getBanks,
};
