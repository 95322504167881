import React from "react";

export default function FinalStepOfertaMail() {
   return (
      <div className="container mt-m-100">
         <div className="row">
            <div className="col-md-12 ml-auto mr-auto text-center border-b-1 pb-50 padding-m5">
               <div className="logo-p7">
                  <img src={require("assets/images/NOVA.png")} alt="Logo" height="150px" />
               </div>
               <p className="text-p7">Ai solicitat sa te contactam. Îți mulțumim!</p>
               <h3 className="">
                  Vei primi un email de confirmare <br />
                  cu detalii despre pașii următori.
               </h3>
               <div className="text-center pt-30">
                  <a
                     href="/"
                     className="back-button mt-50 text-decoration-none"
                     onClick={() => {
                        //sterge sessionStorage
                        sessionStorage.removeItem("sessionCurrentStep");
                        sessionStorage.removeItem("sessionStepsState");
                     }}
                  >
                     Acasă
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}
