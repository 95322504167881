import React from "react";

export default function Switch({ isSwitchOn, handleSwitchClick }) {
   return (
      <div className="switch-container">
         <p>kWh</p>
         <div onClick={handleSwitchClick} className={`switch ${isSwitchOn && "switch--on"}`}>
            <div className="switch__inner" />
         </div>
         <p className={isSwitchOn ? "text-red" : ""}>MWh</p>
      </div>
   );
}
