import React from "react";
import ContractForm from "pages/ContractForm";
import "assets/css/main.scss";
// exclude boostrap from being loaded in the production build
// if (process.env.REACT_APP_ENV === "production") {
//    require("assets/css/main-production.scss");
// } else {
//    require("assets/css/main.scss");
// }

console.log("env", process.env.REACT_APP_ENV);
const App = () => <ContractForm />;

export default App;
