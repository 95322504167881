import React from "react";

export default function Header({ currentStep }) {
   return (
      <section style={{ marginBottom: "30px" }}>
         <div className="container">
            <div id="top" className="col-md-9 mx-auto">
               <div className="row">
                  <h1 className="header-title">Calculator Energie Electrică & Gaze Naturale</h1>
               </div>
               <div className="row">
                  <div className="header-steps-container">
                     <p className="header-scris header-done-p">1. Calculatorul Nova</p>
                     <span className="header-arrows header-done-p">{">>"}</span>
                     <p className={`header-scris ${currentStep >= 5 ? "header-done-p" : ""}`}>2. Date contractuale</p>
                     <span className={`header-arrows ${currentStep >= 5 ? "header-done-p" : ""}`}>{">>"}</span>
                     <p className={`header-scris ${currentStep >= 6 ? "header-done-p" : ""}`}>3. Încarcă documente</p>
                     <span className={`header-arrows ${currentStep >= 6 ? "header-done-p" : ""}`}>{">>"}</span>
                     <p className={`header-scris ${currentStep >= 7 ? "header-done-p" : ""}`}>4. Semnează contractul</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}
