const normalSelectStyle = {
   control: styles => ({ ...styles, backgroundColor: "white", border: "0", borderRadius: "10px", minHeight: "53px" }),
   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
         ...styles,
         backgroundColor: isDisabled ? null : isSelected ? "#f5f5f5" : isFocused ? "#f5f5f5" : null,
         color: isDisabled ? "#ccc" : isSelected ? "#000" : isFocused ? "#000" : "#000",
         cursor: isDisabled ? "not-allowed" : "default",
      };
   },
   singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
};

const errorSelectStyle = {
   control: styles => ({
      ...styles,
      // backgroundImage: `url(${ErrorIcon})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "87% 50%",
      backgroundColor: "white",
      border: "1px solid #f02d4b",
      minHeight: "53px",
      borderRadius: "10px",
   }),
   option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
         ...styles,
         backgroundColor: isDisabled ? null : isSelected ? "#f5f5f5" : isFocused ? "#f5f5f5" : null,
         color: isDisabled ? "#ccc" : isSelected ? "#000" : isFocused ? "#000" : "#000",
         cursor: isDisabled ? "not-allowed" : "default",
      };
   },
   singleValue: (styles, { data }) => ({ ...styles, color: "#000" }),
};

export const styleHelpers = {
   normalSelectStyle,
   errorSelectStyle,
};
