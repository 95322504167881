import React, { useState } from "react";

export default function TextInput({
   label,
   type = "text",
   name,
   value,
   setFieldValue,
   error,
   touched,
   parentClassName,
   descriptionUnderInput = null,
   allowNumbersAndDots = null,
   maxLengthText = undefined,
   forceUpperCase = true,
   isDisabled = false,
}) {
   //DEBOUNCE FUNCTION
   const [debouncedValue, setDebouncedValue] = useState(value);
   const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);

   const allowNumbersAndDotsFunction = e => {
      if (allowNumbersAndDots === null) return;

      const allowedCharacters = /^[0-9.]+$/;
      if (!allowedCharacters.test(e.key) && e.key !== "Backspace" && e.key !== "Delete" && e.key !== "ArrowLeft" && e.key !== "ArrowRight") {
         e.preventDefault();
      }
   };

   const handleDebouncedChange = event => {
      const { name, value } = event.target;
      console.log("🚀 ~ handleDebouncedChange ~ value:", value.toUpperCase());

      console.log("🚀 ~ timeoutId ~ forceUpperCase:", forceUpperCase);
      if (debounceTimeoutId) {
         clearTimeout(debounceTimeoutId);
      }

      const formattedValue = forceUpperCase ? value.toUpperCase() : value;

      const timeoutId = setTimeout(() => {
         setFieldValue(`${name}`, formattedValue);
      }, 150);

      setDebouncedValue(formattedValue);
      setDebounceTimeoutId(timeoutId);
   };

   const inputOnKeyDown = e => {
      allowNumbersAndDotsFunction(e);
   };
   const inputOnChange = e => {
      handleDebouncedChange(e);
   };

   return (
      <div className={parentClassName}>
         <div className="text-input-wrapper">
            <input
               autoComplete="off"
               className={`text-input-input ${error && touched ? "text-input-error" : ""}`}
               type={type}
               disabled={isDisabled}
               id={name}
               name={name}
               placeholder={label}
               value={debouncedValue}
               onChange={inputOnChange}
               onKeyDown={inputOnKeyDown}
               maxLength={maxLengthText}
            />
            <label className="text-input-label" htmlFor={name}>
               {label}
            </label>
         </div>
         {descriptionUnderInput && <p className="description-under-input">{descriptionUnderInput}</p>}
         {error && touched && <p className="error-msg">{error}</p>}
      </div>
   );
}
