import React, { useState } from "react";

import SectionDatePersonale from "components/Step5/SectionDatePersonale";
import SectionDateDeContact from "components/Step5/SectionDateDeContact";
import ReactDOM from "react-dom";
import { apiSteps } from "api/steps.service";
import { toast } from "react-toastify";

function cleanText(input) {
   // Step 1: Remove HTML tags
   let text = input.replace(/<\/?[^>]+(>|$)/g, "");

   // Step 2: Allow only text, numbers, spaces, and punctuation
   text = text.replace(/[^a-zA-Z0-9\s.,!?;:'"-]/g, "");

   return text;
}

export default function DeschideTicket({ formik, setShowOpenTicket, stepsState, consumer_type, tenant_assoc, setCurrentStep }) {
   console.log("🚀 ~ DeschideTicket ~ step4:", stepsState);
   const [text, setText] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   console.log("🚀 ~ DeschideTicket ~ text:", text);
   const onChangeText = e => {
      setText(cleanText(e.target.value));
   };
   const submit = async () => {
      const requestData = {
         ...formik.values,
         description: text || "Fara detalii suplimentare",
         consumer_type,
         tenant_assoc,
         utility_type: stepsState.utility_type,
         monthly_consumption_energy:
            stepsState.monthly_consumption_energy !== "" ? stepsState.monthly_consumption_energy : stepsState.estimated_energy,
         monthly_consumption_gas: stepsState.monthly_consumption_gas !== "" ? stepsState.monthly_consumption_gas : stepsState.estimated_gas,
      };
      setIsLoading(true);

      const response = await apiSteps.submitTicket(requestData);
      if (response.isSuccess) {
         toast.success("Ticketul a fost deschis cu succes! Un reprezentant al echipei noastre te va contacta în cel mai scurt timp.");
         setCurrentStep(1);
      }
      setIsLoading(false);
   };
   return (
      <>
         {ReactDOM.createPortal(
            <div className="modal-declaratie-container">
               <div className="modal-signature-content">
                  <button type="button" onClick={() => setShowOpenTicket(false)} className="modal-declaratie-close-button">
                     x
                  </button>
                  <h3>A apărut o eroare în timpul validării datelor introduse! </h3>
                  <p>
                     Pentru finalizarea procesului de înregistrare a contractului este necesar să contactezi echipa de suport. Dacă dorești să adaugi
                     detalii suplimentare, te rugăm le completezi în câmpul de mai jos.
                  </p>
                  <p>
                     <b> Apasă butonul "Deschide ticket" și un reprezentant al echipei noastre te vă contacta în cel mai scurt timp. </b>
                  </p>
                  <div className="form-group">
                     <div style={{ display: "flex", alignItems: "flex-end", flexDirection: "column", gap: 10, marginBottom: 10 }}>
                        <label htmlFor="deschideTicket" style={{ alignSelf: "flex-start" }} className="form-label">
                           Informatii suplimentare:
                        </label>
                        <textarea
                           className="text-input-input "
                           name="deschideTicket"
                           id="deschideTicket"
                           onChange={onChangeText}
                           value={text}
                           rows={5}
                        />
                        <button disabled={isLoading} onClick={submit} className={`submit-ticket-button`}>
                           {isLoading ? "Procesare..." : "Deschide ticket"}
                        </button>
                     </div>

                     <p style={{ fontStyle: "italic" }}>
                        Te vom contacta folosind datele de contact completate anterior. Daca dorești să modifici aceste date, apasă butonul "X"
                        (sus-dreapta) și modifica datele în formularul folosit anterior.
                     </p>
                     <SectionDatePersonale isUsedInOpenTicketModal={true} formik={formik} consumer_type={consumer_type} tenant_assoc={tenant_assoc} />
                     <SectionDateDeContact isUsedInOpenTicketModal={true} formik={formik} consumer_type={consumer_type} tenant_assoc={tenant_assoc} />
                  </div>
               </div>
            </div>,
            document.getElementById("modal-declaratie"),
         )}
      </>
   );
}
