import React from "react";
import ImgOfertaPJ from "assets/images/NOVA_OFERTE_PJ.png";
import ImgOfertaPF from "assets/images/NOVA_OFERTE_PF.png";

export default function TitleOferta({ isPersoanaJuridica }) {
   return (
      <div className="title-oferta-container">
         {isPersoanaJuridica ? (
            <>
               <h2 className="title-box mb-0">
                  <img src={ImgOfertaPJ} alt="oferte_nova_pj" height="auto" />
               </h2>
               <p className="descriere-title-box">CLIENȚI NOI</p>
            </>
         ) : (
            <>
               <h2 className="title-box mb-0">
                  <img src={ImgOfertaPF} alt="oferte_nova_pf" height="auto" />
               </h2>
               <br />
               <br />
               <p className="descriere-title-box">CLIENȚI NOI</p>
            </>
         )}
      </div>
   );
}
