import React from "react";

export default function TipContractareSelector({ name, value, setFieldValue }) {
   return (
      <div className="container-contractare-selector">
         <button
            type="button"
            onClick={() => setFieldValue(name, "CONTRACTARE_ONLINE")}
            className={`contractare-selector-button b-radius-left-100 ${
               value === "CONTRACTARE_ONLINE" ? "contractare-selector-button-selected" : ""
            }`}
         >
            Contractează online
         </button>
         <button
            onClick={() => setFieldValue(name, "OFERTA_MAIL")}
            type="button"
            className={`contractare-selector-button b-radius-right-100 ${value === "OFERTA_MAIL" ? "contractare-selector-button-selected" : ""}`}
         >
            Ofertă pe mail
         </button>
      </div>
   );
}
