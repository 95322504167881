import React from "react";
import SectionDatePersonale from "components/Step5/SectionDatePersonale";
import SectionDateDeContact from "components/Step5/SectionDateDeContact";
import SectionAdresaDomiciliu from "components/Step5/SectionAdresaDomiciliu";
import SectionIndex from "components/Step5/SectionIndex";
import SectionLocatieFurnizare from "components/Step5/SectionLocatieFurnizare";
import CheckboxInput from "components/inputs/CheckboxInput";

export default function ContractareOnline({ formik, utility_type, consumer_type, tenant_assoc, stateStep1 }) {
   return (
      <>
         <SectionDatePersonale formik={formik} consumer_type={consumer_type} tenant_assoc={tenant_assoc} />
         <SectionDateDeContact formik={formik} consumer_type={consumer_type} tenant_assoc={tenant_assoc} />
         <SectionAdresaDomiciliu stateStep1={stateStep1} formik={formik} utility_type={utility_type} consumer_type={consumer_type} />
         {(utility_type === "CURENT" || utility_type === "GAZ_SI_CURENT") && (
            <CheckboxInput
               label={"Sunt prosumator"}
               name={"isProsumer"}
               value={formik.values.isProsumer}
               error={formik.errors.isProsumer}
               touched={formik.touched.isProsumer}
               handleChange={formik.handleChange}
               parentClassName={"mb-3"}
            />
         )}
         {formik.values.supply_location === "SAME_AS_HOME" && <SectionIndex formik={formik} utility_type={utility_type} />}
         <SectionLocatieFurnizare formik={formik} utility_type={utility_type} consumer_type={consumer_type} />
      </>
   );
}
