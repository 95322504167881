import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ro from "date-fns/locale/ro";

export default function DatePickerCustom({
   containerClassName,
   wrapperClassName,
   pickerClassName,
   placeholderText,
   id,
   setFieldValue,
   objectRoute,
   value,
   error,
   touched,
   label,
   maxDate,
   isDisabled,
}) {
   return (
      <div className={containerClassName} style={{ position: "relative" }}>
         {value && (
            <label htmlFor={id} className="date-picker-label">
               {label}
            </label>
         )}
         <DatePicker
            wrapperClassName={`${wrapperClassName} ${error && touched && "error-datepicker-wrapper"}`}
            className={pickerClassName}
            name={id}
            id={id}
            disabled={isDisabled}
            selected={value ? new Date(value) : ""}
            placeholderText={placeholderText}
            onChange={date => setFieldValue(`${objectRoute}`, date)}
            dateFormat="yyyy-MM-dd"
            locale={ro}
            maxDate={maxDate}
         />
         {error && touched && <p className="error-msg">{error}</p>}
      </div>
   );
}
