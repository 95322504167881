import React, { useState } from "react";
import "assets/css/font-icomoon-d.css";
export default function ConsumatorRadio({ value, name, setFieldValue, error, touched, voltageValue }) {
   const [consumator, setConsumator] = useState("");

   const handleClick = consumator => {
      setConsumator(consumator);
      setFieldValue(name, consumator);
      if (voltageValue === "INALTA_TENSIUNE" && consumator === "pf") setFieldValue("voltage", "");

      if (consumator === "pj") {
         setFieldValue("tenant_assoc", "0");
      }
   };

   const getConsumatorCardClassName = (consumator, selected) => {
      if (value === "") {
         if (selected === consumator) return "consumator-option consumator-option-selected";
         else return "consumator-option";
      } else {
         if (value === selected) return "consumator-option consumator-option-selected";
         else return "consumator-option";
      }
   };
   return (
      <>
         <div id={name} className="mx-auto row consumator-container">
            <label className="custom-label-title">Ce fel de consumator ești?</label>
            <div onClick={() => handleClick("pf")} className={getConsumatorCardClassName(consumator, "pf") + " me-3"}>
               <div className="consumator-icon-pf"></div>
               <p>Persoană fizică</p>
            </div>
            <div onClick={() => handleClick("pj")} className={getConsumatorCardClassName(consumator, "pj")}>
               <div className="consumator-icon-pj"></div>
               <p>Persoană juridică</p>
            </div>
         </div>
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
