import React from "react";
import { FieldArray, FormikProvider } from "formik";
import objectsSchema from "schemas/objectsSchema";
import RadioRow from "components/inputs/RadioRow";
import SectionAdresaDomiciliu from "components/Step5/SectionAdresaDomiciliu";
import SectionIndex from "components/Step5/SectionIndex";
import IconAdd from "assets/images/addIcon.png";

export default function SectionLocatieFurnizare({ formik, utility_type, consumer_type }) {
   console.log("🚀 ~ SectionLocatieFurnizare ~ formik.values.supply_location:", formik.values.supply_location);
   console.log("🚀 ~ locations.map ~  formik.values.switchEnergy:", formik.values.switchEnergy);
   return (
      <>
         <h2 className="custom-label-title">Locație furnizare</h2>
         <RadioRow
            value={formik.values.supply_location}
            name={"supply_location"}
            onChange={formik.handleChange}
            options={objectsSchema.supply_location}
            titleClassName={"col-md-5"}
            labelClassName={"col-md-5"}
         />

         <FormikProvider value={formik}>
            {formik.values.supply_location !== "SAME_AS_HOME" && (
               <FieldArray
                  name="locations"
                  render={arrayHelpers => {
                     const locations = formik.values.locations;
                     console.log("🚀 ~ SectionLocatieFurnizare ~ locations:", locations);

                     return (
                        locations &&
                        locations.length > 0 &&
                        locations.map((locatie, index) => {
                           return (
                              <div key={index}>
                                 {/*{index > 0 && (*/}
                                 {/*    <div className="add-button cursor-pointer"*/}
                                 {/*         onClick={() => arrayHelpers.remove(index)}>*/}
                                 {/*        <p className="text-center mt-50">*/}
                                 {/*            <img alt="remove_icon"*/}
                                 {/*                 src={require("assets/images/removeIcon.png")}*/}
                                 {/*                 className="add-icon"/>*/}
                                 {/*            {' '}Șterge locația cu numărul {index + 1}*/}
                                 {/*        </p>*/}
                                 {/*    </div>*/}
                                 {/*)}*/}
                                 <SectionAdresaDomiciliu
                                    formik={formik}
                                    utility_type={utility_type}
                                    consumer_type={consumer_type}
                                    isInArray={true}
                                    arrayIndex={index}
                                    displayedInFurnizare={true}
                                 />
                                 <SectionIndex formik={formik} utility_type={utility_type} isInArray={true} arrayIndex={index} />
                                 {index === locations.length - 1 && locations.length < 10 && (
                                    <div
                                       className="add-button cursor-pointer"
                                       onClick={() =>
                                          arrayHelpers.push({
                                             ...objectsSchema.locationsSchema,
                                             consumption_agreement_gas: formik.values.consumption_agreement_gas,
                                             consumption_agreement_energy: formik.values.consumption_agreement_energy,
                                             switchEnergy: formik.values.switchEnergy,
                                             switchGas: formik.values.switchGas,
                                             isInit: false,
                                          })
                                       }
                                    >
                                       <p className="text-center mt-50">
                                          <img alt="add_icon" src={IconAdd} className="add-icon" />
                                          {" Adaugă o locație"}
                                       </p>
                                    </div>
                                 )}
                              </div>
                           );
                        })
                     );
                  }}
               />
            )}
         </FormikProvider>
      </>
   );
}
