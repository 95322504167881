import React, { useRef } from "react";
import { checkIfMultipleUploadHasValue, getSizeOfUpload } from "helpers/helpers";
import { pdfjs } from "react-pdf";
import { toast } from "react-toastify";
import "assets/css/font-icomoon-d.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function UploadFileMultiple({
   label,
   value,
   name,
   error,
   touched,
   setFieldValue,
   description,
   accept,
   classNames,
   maxFileSize = null,
}) {
   const uploadRef = useRef(null);

   const reset = () => {
      uploadRef.current.value = "";
   };

   return (
      <>
         <div id={name} className={`${classNames} text-center`}>
            <label id={name} className="cursor-pointer text-center w-100">
               <div className={`upload-file-new-box ${error ? "error-box" : ""}`}>
                  <div className={`upload-file-new-icon-container ${checkIfMultipleUploadHasValue(value) ? "background-green-icomoon-d" : ""}`}>
                     <div className={`upload-file-new-icon-icomoon-d`} />
                  </div>
                  <div className="upload-file-new-box-content">
                     <h2 className={`upload-file-new-box-title ${checkIfMultipleUploadHasValue(value) ? "text-green" : ""}`}>{label}</h2>
                     <h2 className="upload-file-new-box-description">{description}</h2>
                     {checkIfMultipleUploadHasValue(value) ? (
                        value.map((file, index) => {
                           return (
                              <h2 key={index} className="upload-file-new-box-fileName">
                                 {file.name}
                              </h2>
                           );
                        })
                     ) : (
                        <h2 className="upload-file-new-box-fileName">Selectează fișierele</h2>
                     )}
                     {maxFileSize && !value && (
                        <h2 className="upload-file-new-box-fileName">{`*Fisierul trebuie sa aiba o dimensiune maxima de ${maxFileSize} MB`}</h2>
                     )}
                  </div>
               </div>
               <input
                  id={name}
                  onChange={async event => {
                     const files = Array.from(event.currentTarget.files);

                     const acceptedTypes = accept.split(",").map(type => type.trim());

                     const processedFiles = [];
                     const currentFileNames = [];

                     for (let uploadedFile of files) {
                        if (!acceptedTypes.includes(uploadedFile.type)) {
                           toast.error("Tipul unui fisier nu este acceptat");
                           return;
                        }

                        const fileSizeInMB = await getSizeOfUpload(uploadedFile);
                        if (maxFileSize && fileSizeInMB > maxFileSize) {
                           toast.error(`Dimensiunea unui fisier nu poate fi mai mare de ${maxFileSize} MB`);
                           return;
                        }

                        currentFileNames.push(uploadedFile.name);
                        processedFiles.push(uploadedFile);
                     }
                     setFieldValue(name, processedFiles);

                     reset();
                  }}
                  type="file"
                  accept={accept}
                  className="hidden"
                  multiple
                  ref={uploadRef}
               />
            </label>
         </div>
         {error && touched && <p className="error-msg mb-25">{error}</p>}
      </>
   );
}
