import React, { useState } from "react";
import { useFormik } from "formik";
import { goToTop } from "helpers/helpers";
import { apiSteps } from "api/steps.service";
import { step5ValidationSchema } from "schemas/validationSchema";
import { toast } from "react-toastify";
import TipContractareSelector from "components/Step5/TipContractareSelector";
import ContractareOnline from "components/Step5/ContractareOnline";
import OfertaMail from "components/Step5/OfertaMail";
import CheckboxInput from "components/inputs/CheckboxInput";
import NavigateButtons from "components/NavigateButtons";
import { requestJson, RequestMethod } from "helpers/httpHelper";
import prepareData from "schemas/prepareDataSchema";
import DeschideTicket from "components/Step5/DeschideTicket";
export default function Step5({ currentStep, setCurrentStep, stepsState, persistState, setLoading, firstSubmit, setFirstSubmit }) {
   const { utility_type, consumer_type, tenant_assoc, selectedOffers } = stepsState;
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: stepsState.step5,
      validateOnChange: firstSubmit,
      validateOnBlur: false,
      validationSchema: step5ValidationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         goToTop();
         setLoading(true);
         await saveData(values);
         setLoading(false);
         setSubmitting(false);
      },
   });

   const [showOpenTicket, setShowOpenTicket] = useState(false);

   // useEffect(() => {
   //    const updateUnit = () => {
   //       console.log("update unitMeasureEnergy in Step5");
   //       formik.setFieldValue("unitMeasureEnergy", formik.values.switchEnergy ? "mwh" : "kwh");
   //    };

   //    updateUnit();
   // }, [formik.values.switchEnergy]);

   // useEffect(() => {
   //    const updateUnit = () => {
   //       console.log("update unitMeasureGas in Step5");
   //       formik.setFieldValue("unitMeasureGas", formik.values.switchGas ? "mwh" : "kwh");
   //    };

   //    updateUnit();
   // }, [formik.values.switchGas]);

   const mailOffer = async (data, stepsState) => {
      const response = await requestJson("lead/mail", RequestMethod.POST, {
         data: prepareData.mailOffers(data, stepsState),
      });

      if (response.isSuccess) {
         setCurrentStep(9);
         sessionStorage.setItem("sessionCurrentStep", 9);
         toast.success("Solicitarea a fost trimisa.");
      } else {
         toast.error("Solicitarea nu a putut fi trimisa.");
      }
   };

   const saveData = async values => {
      if (values.contract_type === "OFERTA_MAIL") {
         await mailOffer(values, stepsState);
         return;
      }

      const { step5, ...restOfStepsState } = stepsState;

      const consumFinalEnergy = stepsState.monthly_consumption_energy !== "" ? stepsState.monthly_consumption_energy : stepsState.estimated_energy;
      const consumFinalGas = stepsState.monthly_consumption_gas !== "" ? stepsState.monthly_consumption_gas : stepsState.estimated_gas;

      const provider_id_energy = stepsState.step3.provider_id_energy;
      const provider_id_gas = stepsState.step3.provider_id_gas;

      const valuesPreparedForSubmit = prepareData.prepareValuesLead(values, false, consumer_type);
      console.log("🚀 ~ saveData ~ valuesPreparedForSubmit:", valuesPreparedForSubmit);
      const valuesPreparedForState = prepareData.prepareValuesLead(values, true, consumer_type);

      const response = await apiSteps.createUpdateLeadNew(
         { ...valuesPreparedForSubmit, ...restOfStepsState },
         tenant_assoc,
         selectedOffers,
         prepareData.transformForPJInKWh(consumFinalEnergy),
         prepareData.transformForPJInKWh(consumFinalGas),
         provider_id_energy,
         provider_id_gas,
      );
      // const response = { isSuccess: true, response: { data: {} } };

      if (response.isSuccess) {
         setCurrentStep(currentStep + 1);
         sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
         setFirstSubmit(false);
         persistState(prevState => {
            console.log("🚀 ~ saveData ~ prevState:", prevState);
            return {
               ...prevState,
               step5: {
                  ...prevState.step5,
                  ...valuesPreparedForState,
               },
               lead_object: response?.response?.data,
            };
         });
      } else {
         setLoading(false);
         if (response?.status === 409) {
            setShowOpenTicket(true);
            persistState(prevState => {
               console.log("🚀 ~ saveData ~ prevState:", prevState);
               return {
                  ...prevState,
                  step5: {
                     ...prevState.step5,
                     ...valuesPreparedForState,
                  },
               };
            });
         }
      }
   };

   const formSubmition = e => {
      e.preventDefault();
      setFirstSubmit(true);
      formik.handleSubmit();
   };

   return (
      <section className="pb-55">
         <div className="container">
            <div className="col-md-9 mx-auto">
               <h2 className="step-title">Date contractuale</h2>
               <h3 className="step-description col-md-9 mx-auto">
                  Super! Dacă ai ajuns aici, înseamnă că ai ales oferta. Completează datele contractuale și noi vom genera contractul pe care îl poți
                  semna digital într-un pas următor.
               </h3>
               <form onSubmit={formSubmition}>
                  <TipContractareSelector name={"contract_type"} setFieldValue={formik.setFieldValue} value={formik.values.contract_type} />

                  {formik.values.contract_type === "CONTRACTARE_ONLINE" ? (
                     <ContractareOnline
                        stateStep1={stepsState.step1}
                        formik={formik}
                        utility_type={utility_type}
                        consumer_type={consumer_type}
                        tenant_assoc={tenant_assoc}
                     />
                  ) : (
                     <OfertaMail
                        formikValues={formik.values}
                        formikErrors={formik.errors}
                        formikTouched={formik.touched}
                        setFieldValue={formik.setFieldValue}
                     />
                  )}
                  <CheckboxInput
                     name={"gdpr_consent"}
                     label={"Accept politica de prelucrare a datelor"}
                     value={formik.values.gdpr_consent}
                     error={formik.errors.gdpr_consent}
                     touched={formik.touched.gdpr_consent}
                     handleChange={formik.handleChange}
                     parentClassName={"mb-3"}
                  />
                  <CheckboxInput
                     name={"marketing_consent"}
                     label={"Sunt de acord să primesc mesaje de informare cu privire la ofertele Nova Power & Gas"}
                     value={formik.values.marketing_consent}
                     error={formik.errors.marketing_consent}
                     touched={formik.touched.marketing_consent}
                     handleChange={formik.handleChange}
                  />

                  <NavigateButtons
                     isNextDisabled={formik.isSubmitting}
                     formikErrors={formik.errors}
                     currentStep={currentStep}
                     setCurrentStep={setCurrentStep}
                  />
               </form>

               {showOpenTicket && (
                  <DeschideTicket
                     utility_type={utility_type}
                     consumer_type={consumer_type}
                     tenant_assoc={tenant_assoc}
                     stepsState={stepsState}
                     formik={formik}
                     submit={formik.handleSubmit}
                     setShowOpenTicket={setShowOpenTicket}
                     setCurrentStep={setCurrentStep}
                  />
               )}
            </div>
         </div>
      </section>
   );
}
