import React, { useState, useEffect } from "react";
import { apiNomenclature } from "api/nomenclature.service";
import objectsSchema from "schemas/objectsSchema";
import TextInput from "components/inputs/TextInput";
import CustomSelect from "components/inputs/CustomSelect";
// import ConventieConsum from "components/Step5/ConventieConsum";

export default function SectionAdresaDomiciliu({ stateStep1, formik, utility_type, consumer_type, isInArray = false, arrayIndex }) {
   console.log("🚀 ~ SectionAdresaDomiciliu ~ isInArray:", isInArray);
   const [optionsCounties, setOptionsCounties] = useState([]);
   const [optionsCities, setOptionsCities] = useState([]);
   const [optionsStreets, setOptionsStreets] = useState([]);
   const [optionsPostalCodes, setOptionsPostalCodes] = useState([]);
   const modifiedOptionsTensiune = consumer_type !== "pj" ? objectsSchema.optionsTensiune.slice(0, -1) : objectsSchema.optionsTensiune;

   const getOptionsCounties = async () => {
      const response = await apiNomenclature.getCounties();
      setOptionsCounties(
         response.data.map(item => {
            return { value: item.county_id, label: item.county };
         }),
      );
   };

   const getOptionsCities = async () => {
      const requestValue = isInArray ? formik.values.locations?.[arrayIndex].county_id : formik.values.county_id;
      const response = await apiNomenclature.getCitiesByCounty(requestValue);
      setOptionsCities(
         response.response.data.map(item => {
            return { value: item.city_id, label: item.city };
         }),
      );
   };

   const getOptionsStreets = async () => {
      const requestValue = isInArray ? formik.values.locations?.[arrayIndex].city_id : formik.values.city_id;
      const response = await apiNomenclature.getStreetsByCity(requestValue);
      setOptionsStreets(
         response.data.map(item => {
            return { value: item.street_id, label: item.street };
         }),
      );
   };

   const getOptionsPostalCodes = async () => {
      const requestValue = isInArray ? formik.values.locations?.[arrayIndex].street_id : formik.values.street_id;
      const response = await apiNomenclature.getPostalCodesByStreet(requestValue);
      setOptionsPostalCodes(
         response.data.map(item => {
            return { value: item.postal_code, label: item.postal_code };
         }),
      );
   };

   useEffect(() => {
      getOptionsCounties();
   }, []);
   const areJudetAndOrasDisabled = formik.values.supply_location === "SAME_AS_HOME";
   useEffect(() => {
      if (
         !areJudetAndOrasDisabled &&
         isInArray &&
         formik.values.locations?.[arrayIndex]?.county_id === "" &&
         formik.values.locations?.[arrayIndex]?.city_id === ""
      ) {
         formik.setFieldValue(`locations[${arrayIndex}].county_id`, formik.values.county_id);
         formik.setFieldValue(`locations[${arrayIndex}].city_id`, formik.values.city_id);
      }

      //avoid to block the fields with empty strings if user selected again "same address as home" option
      // after he selected a different address and did not fill the fields after that
      if (
         areJudetAndOrasDisabled &&
         !isInArray &&
         stateStep1?.county_id &&
         stateStep1?.city_id &&
         formik.values.county_id === "" &&
         formik.values.city_id === ""
      ) {
         formik.setFieldValue(`county_id`, stateStep1.county_id);
         formik.setFieldValue(`city_id`, stateStep1.city_id);
      }
   }, [areJudetAndOrasDisabled, isInArray]);

   useEffect(() => {
      if (!isInArray && formik.values.county_id) {
         getOptionsCities();
      }
      if (isInArray && formik.values.locations?.[arrayIndex]?.county_id) {
         getOptionsCities();
      }
   }, [formik.values.county_id, formik.values.locations?.[arrayIndex]?.county_id]);

   useEffect(() => {
      if (!isInArray && formik.values.city_id) {
         getOptionsStreets();
      }
      if (isInArray && formik.values.locations?.[arrayIndex]?.city_id) {
         getOptionsStreets();
      }
   }, [formik.values.city_id, formik.values.locations?.[arrayIndex]?.city_id]);

   useEffect(() => {
      if (!isInArray && formik.values.street_id) {
         getOptionsPostalCodes();
      }
      if (isInArray && formik.values.locations?.[arrayIndex]?.street_id) {
         getOptionsPostalCodes();
      }
   }, [formik.values.street_id, , formik.values.locations?.[arrayIndex]?.street_id]);
   useEffect(() => {
      const updateUnit = () => {
         if (isInArray) {
            console.log("update unitMeasureEnergy", formik.values.locations?.[arrayIndex]?.switchEnergy ? "mwh" : "kwh");
            formik.setFieldValue(`locations[${arrayIndex}].unitMeasureEnergy`, formik.values.locations?.[arrayIndex]?.switchEnergy ? "mwh" : "kwh");
         }
      };

      updateUnit();
   }, [formik.values.locations?.[arrayIndex]?.switchEnergy]);

   useEffect(() => {
      const updateUnit = () => {
         if (isInArray) {
            console.log("update unitMeasureGas", formik.values.locations?.[arrayIndex]?.switchGas ? "mwh" : "kwh");
            formik.setFieldValue(`locations[${arrayIndex}].unitMeasureGas`, formik.values.locations?.[arrayIndex]?.switchGas ? "mwh" : "kwh");
         }
      };

      updateUnit();
   }, [formik.values.locations?.[arrayIndex]?.switchGas]);
   return (
      <>
         {!isInArray && <h2 className="custom-label-title">Adresă de domiciliu</h2>}
         <div className="row mb-25">
            <CustomSelect
               options={optionsCounties}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.county_id : formik.values.county_id}
               onChange={value => {
                  if (isInArray) {
                     formik.setFieldValue(`locations[${arrayIndex}].county_id`, value.value);
                     formik.setFieldValue(`locations[${arrayIndex}].city_id`, "");
                     formik.setFieldValue(`locations[${arrayIndex}].street_id`, "");
                     formik.setFieldValue(`locations[${arrayIndex}].postal_code`, "");
                  } else {
                     formik.setFieldValue("county_id", value.value);
                     formik.setFieldValue("city_id", "");
                     formik.setFieldValue("street_id", "");
                     formik.setFieldValue("postal_code", "");
                  }
                  setOptionsCities([]);
                  setOptionsStreets([]);
                  setOptionsPostalCodes([]);
               }}
               placeholder="Alege județul..."
               name={isInArray ? `locations[${arrayIndex}].county_id` : "county_id"}
               label={`Județul  de ${isInArray ? "consum" : "domiciliu"}`}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.county_id : formik.errors.county_id}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.county_id : formik.touched.county_id}
               parentClassName={"col-md-6 relative input-row"}
               labelClassName={"label-select"}
               isDisabled={areJudetAndOrasDisabled}
            />

            <CustomSelect
               options={optionsCities}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.city_id : formik.values.city_id}
               onChange={value => {
                  if (isInArray) {
                     formik.setFieldValue(`locations[${arrayIndex}].city_id`, value.value);
                     formik.setFieldValue(`locations[${arrayIndex}].street_id`, "");
                     formik.setFieldValue(`locations[${arrayIndex}].postal_code`, "");
                  } else {
                     formik.setFieldValue("city_id", value.value);
                     formik.setFieldValue("street_id", "");
                     formik.setFieldValue("postal_code", "");
                  }
                  setOptionsStreets([]);
                  setOptionsPostalCodes([]);
               }}
               placeholder="Alege orasul..."
               name={isInArray ? `locations[${arrayIndex}].city_id` : "city_id"}
               label={`Orasul de ${isInArray ? "consum" : "domiciliu"}`}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.city_id : formik.errors.city_id}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.city_id : formik.touched.city_id}
               parentClassName={"col-md-6 relative"}
               labelClassName={"label-select"}
               isDisabled={areJudetAndOrasDisabled}
            />
         </div>
         <div className="row mb-25">
            <CustomSelect
               options={optionsStreets}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.street_id : formik.values.street_id}
               onChange={value => {
                  if (isInArray) {
                     formik.setFieldValue(`locations[${arrayIndex}].street_id`, value.value);
                     formik.setFieldValue(`locations[${arrayIndex}].postal_code`, "");
                  } else {
                     formik.setFieldValue("street_id", value.value);
                     formik.setFieldValue("postal_code", "");
                  }
                  setOptionsPostalCodes([]);
               }}
               placeholder="Alege strada..."
               name={isInArray ? `locations[${arrayIndex}].street_id` : "street_id"}
               label={"Strada"}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.street_id : formik.errors.street_id}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.street_id : formik.touched.street_id}
               parentClassName={"col-md-6 relative input-row"}
               labelClassName={"label-select"}
            />
            <div className="col-md-6 col-12 mb-0">
               <div className="row">
                  <TextInput
                     label={"Numar"}
                     name={isInArray ? `locations[${arrayIndex}].street_number` : "street_number"}
                     value={isInArray ? formik.values.locations?.[arrayIndex]?.street_number : formik.values.street_number}
                     error={isInArray ? formik.errors.locations?.[arrayIndex]?.street_number : formik.errors.street_number}
                     touched={isInArray ? formik.touched.locations?.[arrayIndex]?.street_number : formik.touched.street_number}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-6 input-row"}
                  />
                  <TextInput
                     label={"Bloc"}
                     name={isInArray ? `locations[${arrayIndex}].building` : "building"}
                     value={isInArray ? formik.values.locations?.[arrayIndex]?.building : formik.values.building}
                     error={isInArray ? formik.errors.locations?.[arrayIndex]?.building : formik.errors.building}
                     touched={isInArray ? formik.touched.locations?.[arrayIndex]?.building : formik.touched.building}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-6 input-row"}
                  />
               </div>
            </div>
         </div>
         <div className="row mb-25">
            <TextInput
               label={"Scara"}
               name={isInArray ? `locations[${arrayIndex}].entrance` : "entrance"}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.entrance : formik.values.entrance}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.entrance : formik.errors.entrance}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.entrance : formik.touched.entrance}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-6 col-md-3 input-row"}
            />
            <TextInput
               label={"Etaj"}
               name={isInArray ? `locations[${arrayIndex}].floor` : "floor"}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.floor : formik.values.floor}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.floor : formik.errors.floor}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.floor : formik.touched.floor}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-6 col-md-3 input-row"}
            />
            <TextInput
               label={"Apartment"}
               name={isInArray ? `locations[${arrayIndex}].apartment` : "apartment"}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.apartment : formik.values.apartment}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.apartment : formik.errors.apartment}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.apartment : formik.touched.apartment}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-6 col-md-3 input-row"}
            />
            <CustomSelect
               options={optionsPostalCodes}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.postal_code : formik.values.postal_code}
               onChange={value => {
                  if (isInArray) formik.setFieldValue(`locations[${arrayIndex}].postal_code`, value.value);
                  else formik.setFieldValue("postal_code", value.value);
               }}
               placeholder="Alege codul..."
               name={isInArray ? `locations[${arrayIndex}].postal_code` : "postal_code"}
               label={"Cod postal"}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.postal_code : formik.errors.postal_code}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.postal_code : formik.touched.postal_code}
               parentClassName={"col-6 col-md-3 relative input-row"}
               labelClassName={"label-select"}
               isSearchable={false}
            />
         </div>
         {(formik.values.supply_location === "SAME_AS_HOME" || isInArray) && (
            <div className="row mb-25">
               {(utility_type === "CURENT" || utility_type === "GAZ_SI_CURENT") && (
                  <CustomSelect
                     options={modifiedOptionsTensiune}
                     value={isInArray ? formik.values.locations?.[arrayIndex]?.voltage : formik.values.voltage}
                     onChange={value => {
                        if (isInArray) formik.setFieldValue(`locations[${arrayIndex}].voltage`, value.value);
                        else formik.setFieldValue("voltage", value.value);
                     }}
                     placeholder="Nivel Tensiune"
                     name={isInArray ? `locations[${arrayIndex}].voltage` : "voltage"}
                     label={"Nivel tensiune"}
                     error={isInArray ? formik.errors.locations?.[arrayIndex]?.voltage : formik.errors.voltage}
                     touched={isInArray ? formik.touched.locations?.[arrayIndex]?.voltage : formik.touched.voltage}
                     parentClassName={"col-6 relative"}
                     labelClassName={"label-select"}
                  />
               )}
            </div>
         )}
      </>
   );
}
