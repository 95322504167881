import React from "react";

export default function RadioRow({ title, options, onChange, value, name, titleClassName, labelClassName }) {
   return (
      <div className="radio-wrapper-13 row mb-3">
         {title && <h3 className={`radio-row-title ${titleClassName}`}>{title}</h3>}
         {options.map((option, index) => {
            return (
               <label className={`${labelClassName} mb-m-10`} key={index}>
                  <input id={name} name={name} type="radio" value={option.value} checked={value === option.value} onChange={onChange} />
                  <span className="radio-row-option-text">{option.label}</span>
               </label>
            );
         })}
      </div>
   );
}
