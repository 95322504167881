import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { step7ValidationSchema } from "schemas/validationSchema";
import { goToTop } from "helpers/helpers";
import { functionHelpers } from "helpers/functionHelpers";
import { apiSteps } from "api/steps.service";
import ModalSignature from "components/Step7/ModalSignature";
import NavigateButtons from "components/NavigateButtons";
import { toast } from "react-toastify";
import { useFetchPdfsWithRetry } from "helpers/hooks";
const base64ToBlobUrl = (base64, contentType = "application/pdf") => {
   try {
      // Convert base64 to a Uint8Array
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
         bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the Uint8Array
      const blob = new Blob([bytes], { type: contentType });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      return blobUrl;
   } catch (e) {
      console.error("Error converting base64 string to blob URL:", e);
      // Return a fallback or indicate an error in a way that your app can handle.
      return "";
   }
};

export default function Step7({ currentStep, setCurrentStep, stepsState, persistState, setLoading, firstSubmit, setFirstSubmit }) {
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: stepsState.step7,
      validateOnChange: firstSubmit,
      validateOnBlur: false,
      validationSchema: step7ValidationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         goToTop();
         setLoading(true);
         await saveData(values);
         setLoading(false);
         setSubmitting(false);
      },
   });

   const { utility_type } = stepsState;
   const [isModalSignatureOpen, setIsModalSignatureOpen] = useState(false);

   const [selectedUtility, setSelectedUtility] = useState(functionHelpers.getInitialSelectedUtility(utility_type));
   const utilityPdfs = selectedUtility === "GAZ" ? formik.values.pdfs?.gas : formik.values.pdfs?.energy;

   const [isFetchingDocs, setIsFetchingDocs] = useState(false);

   const [hasReceivedDocs, setHasReceivedDocs] = useState(Boolean(utilityPdfs));

   const [retry, setRetry] = useState(0);
   const actions = {
      actionsForFail: () => {
         if (utilityPdfs) {
            return;
         }
         setHasReceivedDocs(false);
         setIsFetchingDocs(false);
      },
      actionsForSuccess: response => {
         setHasReceivedDocs(true);
         persistState(prevState => {
            return {
               ...prevState,
               step7: {
                  ...prevState.step7,
                  pdfs: response,
               },
            };
         });
      },
   };
   useFetchPdfsWithRetry(stepsState, actions, retry, setIsFetchingDocs);

   const objKeysGas = formik.values.pdfs?.gas ? Object.keys(formik.values.pdfs?.gas) : [];
   const objKeysEnergy = formik.values.pdfs?.energy ? Object.keys(formik.values.pdfs?.energy) : [];

   const initialPdfTitleGas = objKeysGas.length > 0 ? objKeysGas[0] : null;
   const initialPdfTitleEnergy = objKeysEnergy.length > 0 ? objKeysEnergy[0] : null;

   const initialPdfTitle = selectedUtility === "GAZ" ? initialPdfTitleGas : initialPdfTitleEnergy;
   const initialUrl = selectedUtility === "GAZ" ? formik.values.pdfs?.gas?.[initialPdfTitle] : formik.values.pdfs?.energy?.[initialPdfTitle];

   const [documentPdf, setDocumentPdf] = useState({
      title: initialPdfTitle || "",
      url: initialUrl ? base64ToBlobUrl(initialUrl) : "",
   });

   const updateDocumentPdf = (title, base64Content) => {
      try {
         const blobUrl = base64ToBlobUrl(base64Content);
         setDocumentPdf({ title, url: blobUrl });
      } catch (err) {}
   };

   useEffect(() => {
      if (utilityPdfs) {
         const pdfTitle = Object.keys(utilityPdfs)[0];
         updateDocumentPdf(pdfTitle, utilityPdfs[pdfTitle]);
         setIsFetchingDocs(false);
      }
   }, [selectedUtility, formik.values.pdfs]);

   const resultsGas =
      objKeysGas.length > 0
         ? Object.entries(formik.values.pdfs?.gas).map(([key, value]) => ({
              title: key,
              url: value,
           }))
         : [];
   const resultsEnergy =
      objKeysEnergy.length > 0
         ? Object.entries(formik.values.pdfs?.energy).map(([key, value]) => ({
              title: key,
              url: value,
           }))
         : [];

   const saveData = async values => {
      const { lead_object } = stepsState;
      const response = await apiSteps.signature({ ...values, lead_object: lead_object });

      if (response.isSuccess) {
         persistState(prevState => {
            return {
               ...prevState,
               step7: {
                  ...prevState.step7,
                  signature: values.signature,
               },
            };
         });
         // setCurrentStep(currentStep + 1);
         // sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
         setCurrentStep(currentStep + 1);
         sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
      } else {
         toast.error("problem");
      }
   };

   const formSubmition = e => {
      e.preventDefault();
      setFirstSubmit(true);
      formik.handleSubmit();
   };
   const retryFetching = () => {
      setRetry(prev => prev + 1);
      setHasReceivedDocs(false);
      setIsFetchingDocs(true);
   };
   const renderRetryFetchingDocs = () => {
      return (
         <div>
            <div className="d-flex p-2 flex-column align-items-center col-md-9 mx-auto">
               <p className="text-align-center">
                  Generarea documentelor nu a fost complet finalizată. Vă rugăm să încercați din nou în câteva momente.{" "}
               </p>
               <button className="next-button" onClick={retryFetching}>
                  Reîncearcă
               </button>
            </div>
            <NavigateButtons finalize={true} currentStep={currentStep} setCurrentStep={setCurrentStep} persistState={persistState} />
         </div>
      );
   };

   const renderLoading = () => {
      return (
         <div className="d-flex p-2 flex-column align-items-center">
            <div className="loader loader-1">
               <div className="loader-outter" />
               <div className="loader-inner" />
            </div>
            <span>
               Aplicatia genereaza documentele. <br /> Va rugam asteptati cateva momente.
            </span>
         </div>
      );
   };

   const renderForm = () => {
      return (
         <form onSubmit={formSubmition}>
            <div className="documents-header">
               <div className="documents-header-row1">
                  {(utility_type === "GAZ_SI_CURENT" || utility_type === "GAZ") && (
                     <h3
                        onClick={() => {
                           if (selectedUtility !== "GAZ") {
                              setSelectedUtility("GAZ");
                              const gasPdfTitle = objKeysGas[0];
                              const gasPdfContent = formik.values.pdfs.gas[gasPdfTitle];
                              updateDocumentPdf(gasPdfTitle, gasPdfContent);
                           }
                        }}
                        className={`${selectedUtility === "GAZ" ? "selected-utility-type" : ""}`}
                     >
                        Gaz
                     </h3>
                  )}
                  {(utility_type === "GAZ_SI_CURENT" || utility_type === "CURENT") && (
                     <h3
                        onClick={() => {
                           if (selectedUtility !== "CURENT") {
                              setSelectedUtility("CURENT");
                              const energyPdfTitle = objKeysEnergy[0];
                              const energyPdfContent = formik.values.pdfs.energy[energyPdfTitle];
                              updateDocumentPdf(energyPdfTitle, energyPdfContent);
                           }
                        }}
                        className={`${selectedUtility === "CURENT" ? "selected-utility-type" : ""}`}
                     >
                        Energie Electrică
                     </h3>
                  )}
               </div>
               <div className="documents-header-row2">
                  {(utility_type === "GAZ_SI_CURENT" || utility_type === "GAZ") &&
                     selectedUtility === "GAZ" &&
                     resultsGas.map((item, index) => {
                        return (
                           <h3
                              onClick={() => updateDocumentPdf(item.title, item.url)}
                              className={`${item.title === documentPdf.title ? "selected-pdf" : ""}`}
                              key={index}
                           >
                              {item.title}
                           </h3>
                        );
                     })}

                  {(utility_type === "GAZ_SI_CURENT" || utility_type === "CURENT") &&
                     selectedUtility === "CURENT" &&
                     resultsEnergy.map((item, index) => {
                        return (
                           <h3
                              onClick={() => updateDocumentPdf(item.title, item.url)}
                              className={`${item.title === documentPdf.title ? "selected-pdf" : ""}`}
                              key={index}
                           >
                              {item.title}
                           </h3>
                        );
                     })}
               </div>
            </div>
            <div>
               <iframe title="pdf file" width={"100%"} height={"500px"} className="pdf-preview" id="pdf-preview" src={documentPdf.url}></iframe>
            </div>
            <div className="documents-footer">
               <h3 onClick={() => setIsModalSignatureOpen(true)}>Semnează aici</h3>
            </div>
            {formik.errors.signature && formik.touched.signature && <p className="error-msg">{formik.errors.signature}</p>}
            <NavigateButtons
               next={hasReceivedDocs ? null : "display-none"}
               finalize={true}
               currentStep={currentStep}
               setCurrentStep={setCurrentStep}
               persistState={persistState}
               isNextDisabled={formik.isSubmitting}
            />
         </form>
      );
   };

   return (
      <>
         <section className="pb-55">
            <div className="container">
               <div className="col-md-9 mx-auto">
                  <h2 className="step-title">Citește și semnează contractul</h2>
                  <h3 className="step-description col-md-9 mx-auto">
                     Contractul include toate documentele necesare pentru ca noi să putem activa serviciile solicitate, inclusiv rezilierea
                     contractului cu furnizorul actual. Poți semna direct cu mouse-ul sau cu degetul pe ecranul mobilului.
                  </h3>
                  {isFetchingDocs ? renderLoading() : hasReceivedDocs ? renderForm() : renderRetryFetchingDocs()}
               </div>
            </div>
         </section>

         {isModalSignatureOpen && (
            <ModalSignature
               setFieldValue={formik.setFieldValue}
               signatureValue={formik.values.signature}
               setIsModalSignatureOpen={setIsModalSignatureOpen}
            />
         )}
      </>
   );
}
