import React from "react";
import { goToTop, refToErrorFormik } from "helpers/helpers";

export default function NavigateButtons({
   formikErrors,
   currentStep,
   setCurrentStep,
   back,
   next,
   finalize,
   isNextDisabled = false,
   persistState = () => {
      console.warn("persist state is not set in NavigateButtons");
   },
}) {
   const goBack = () => {
      let nrOfStepsToGoBack = 1;

      //ascundere pas2 din formular - pas2(oferta simplificata) nu mai este necesar
      if (currentStep === 3) {
         setCurrentStep(currentStep - 2);
         nrOfStepsToGoBack = 2;
      } else {
         setCurrentStep(currentStep - 1);
      }

      sessionStorage.setItem("sessionCurrentStep", currentStep - nrOfStepsToGoBack);

      if (currentStep === 6) {
         //sterge pdfs
         persistState(prevState => {
            return {
               ...prevState,
               step7: {
                  ...prevState.step7,
                  pdfs: null,
               },
            };
         });
      }

      goToTop();
   };

   const handleOnNextClick = () => {
      if (formikErrors) {
         refToErrorFormik(formikErrors);
      }
   };

   return (
      <div className="text-center pt-30">
         <button type="button" onClick={() => goBack()} className={`${back} back-button`}>
            Înapoi
         </button>
         {next !== "display-none" && (
            <button disabled={isNextDisabled} onClick={() => handleOnNextClick()} type="submit" className={`${next} next-button`}>
               {finalize ? "Finalizează" : "Continuă"}
            </button>
         )}
      </div>
   );
}
