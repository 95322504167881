import * as yup from "yup";
import { storageHelpers } from "helpers/storageHelpers";
import { checkIfEmptyObject } from "helpers/helpers";

const cnpRegex = /^[1-2,5-8]\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d|5[0-2]|99)(00[1-9]|0[1-9]\d|[1-9]\d\d)\d$/;
const phoneRegex = /^0\d{9}$/;

export const step1ValidationSchema = yup.object().shape({
   utility_type: yup.string().required("Tipul de utilitate este obligatoriu"),
   consumer_type: yup.string().required("Tipul de consumator este obligatoriu"),
   voltage: yup.string().when("utility_type", {
      is: value => ["CURENT", "GAZ_SI_CURENT"].includes(value),
      then: schema => schema.required("Nivelul de tensiune este obligatoriu"),
      otherwise: schema => schema,
   }),
   tenant_assoc: yup.mixed().test("tenant_assoc", "Asociația de proprietari este obligatorie", (value, parent) => {
      const consumer_type = parent.parent.consumer_type;

      if (consumer_type === "pj") return true;
      if (!value) return false;

      return true;
   }),

   //    consumption_category: yup.string().when("utility_type", {
   //       is: value => ["GAZ", "GAZ_SI_CURENT"].includes(value),
   //       then: schema => schema.required("Categoria de consum este obligatorie"),
   //       otherwise: schema => schema,
   //    }),
   county_id: yup.string().required("Județul este obligatoriu"),
   city_id: yup.string().required("Localitatea este obligatorie"),
});

export const step3ValidationSchema = yup.object().shape({
   surface_area: yup.string().required("Suprafața este obligatorie"),
   voltage: yup.mixed().test("voltage", "Nivelul de tensiune este obligatoriu", (value, parent) => {
      const tipFurnizare = storageHelpers.getTipFurnizare();

      if (tipFurnizare === "GAZ") return true;
      if ((tipFurnizare === "GAZ_SI_CURENT" || tipFurnizare === "CURENT") && value) return true;

      return false;
   }),
   provider_id_gas: yup.mixed().test("provider_id_gas", "Furnizorul de gaze este obligatoriu", (value, parent) => {
      const tipFurnizare = storageHelpers.getTipFurnizare();

      if (tipFurnizare === "CURENT") return true;
      if ((tipFurnizare === "GAZ" || tipFurnizare === "GAZ_SI_CURENT") && value) return true;

      return false;
   }),
   provider_id_energy: yup.mixed().test("provider_id_energy", "Furnizorul de curent este obligatoriu", (value, parent) => {
      const tipFurnizare = storageHelpers.getTipFurnizare();

      if (tipFurnizare === "GAZ") return true;
      if ((tipFurnizare === "CURENT" || tipFurnizare === "GAZ_SI_CURENT") && value) return true;

      return false;
   }),
});

export const step5ValidationSchema = yup.object().shape({
   last_name: yup.string().required("Numele este obligatoriu"),
   first_name: yup.string().required("Prenumele este obligatoriu"),
   ci_cnp: yup.mixed().test("ci_cnp", "CNP-ul este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "OFERTA_MAIL" || tipPersoana === "pj") return true;

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      if (!value) return false;
      const validation = cnpRegex.test(value);
      if (!validation) throw new yup.ValidationError("CNP-ul nu are formatul corect", parent.parent, parent.path);

      return true;
   }),
   ci_sn: yup.mixed().test("ci_sn", "Seria CI este obligatorie", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "OFERTA_MAIL" || tipPersoana === "pj") return true;

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }
      if (!value) return false;

      return true;
   }),
   ci_number: yup.mixed().test("ci_number", "Numărul CI este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "OFERTA_MAIL" || tipPersoana === "pj") return true;

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      if (!value) return false;

      return true;
   }),
   ci_issuer: yup.mixed().test("ci_issuer", "Eliberatorul CI este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "OFERTA_MAIL" || tipPersoana === "pj") return true;

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      if (!value) return false;

      return true;
   }),
   ci_date: yup.mixed().test("ci_date", "Data eliberării CI este obligatorie", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "OFERTA_MAIL" || tipPersoana === "pj") return true;

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      if (!value) return false;

      return true;
   }),

   company_name: yup.mixed().test("company_name", "Numele firmei este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && !estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      return true;
   }),
   company_cif: yup.mixed().test("company_cif", "CIF-ul este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && !estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      return true;
   }),
   company_bank_account: yup.mixed().test("company_bank_account", "Contul bancar este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && !estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      return true;
   }),
   company_bank_id: yup.mixed().test("company_bank_id", "Banca este obligatorie", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (contract_type === "CONTRACTARE_ONLINE") {
         if (tipPersoana === "pf" && !estePFCuAsociatie) {
            return true;
         } else {
            if (!value) return false;
         }
      }

      return true;
   }),

   email: yup.string().required("Email-ul este obligatoriu").email("Email-ul nu este valid"),
   phone_number: yup.mixed().test("phone_number", "Numărul de telefon este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;

      if (!value) return false;
      const validation = phoneRegex.test(value);
      if (!validation) throw new yup.ValidationError("Numărul de telefon nu are formatul corect", parent.parent, parent.path);

      return true;
   }),

   county_id: yup.mixed().test("county_id", "Județul este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;
      if (!value) return false;

      return true;
   }),
   city_id: yup.mixed().test("city_id", "Localitatea este obligatorie", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;
      if (!value) return false;

      return true;
   }),
   street_id: yup.mixed().test("street_id", "Strada este obligatorie", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;
      if (!value) return false;

      return true;
   }),
   street_number: yup.mixed().test("street_number", "Numărul este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;
      if (!value) return false;

      return true;
   }),

   //    building: yup.string(),
   //    entrance: yup.string(),
   //    floor: yup.string(),
   //    apartment: yup.string(),
   postal_code: yup.mixed().test("postal_code", "Codul poștal este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;

      if (contract_type === "OFERTA_MAIL") return true;
      if (!value) return false;

      return true;
   }),
   voltage: yup.mixed().test("voltage", "Nivelul de tensiune este obligatoriu", (value, parent) => {
      const contract_type = parent.parent.contract_type;
      const locatieFurnizare = parent.options.context.supply_location;
      const tipFurnizare = storageHelpers.getTipFurnizare();

      if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
      if (!value) return false;

      return true;
   }),

   consumption_agreement_energy: yup.object().shape({
      january_kwh: yup.mixed().test("january_kwh", "Consumul din ianuarie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      february_kwh: yup.mixed().test("february_kwh", "Consumul din februarie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      march_kwh: yup.mixed().test("march_kwh", "Consumul din martie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      april_kwh: yup.mixed().test("april_kwh", "Consumul din aprilie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      may_kwh: yup.mixed().test("may_kwh", "Consumul din mai este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      june_kwh: yup.mixed().test("june_kwh", "Consumul din iunie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      july_kwh: yup.mixed().test("july_kwh", "Consumul din iulie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      august_kwh: yup.mixed().test("august_kwh", "Consumul din august este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      september_kwh: yup.mixed().test("september_kwh", "Consumul din septembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      october_kwh: yup.mixed().test("october_kwh", "Consumul din octombrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      november_kwh: yup.mixed().test("november_kwh", "Consumul din noiembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      december_kwh: yup.mixed().test("december_kwh", "Consumul din decembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
   }),
   consumption_agreement_gas: yup.object().shape({
      january_kwh: yup.mixed().test("january_kwh", "Consumul din ianuarie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      february_kwh: yup.mixed().test("february_kwh", "Consumul din februarie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      march_kwh: yup.mixed().test("march_kwh", "Consumul din martie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      april_kwh: yup.mixed().test("april_kwh", "Consumul din aprilie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      may_kwh: yup.mixed().test("may_kwh", "Consumul din mai este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      june_kwh: yup.mixed().test("june_kwh", "Consumul din iunie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      july_kwh: yup.mixed().test("july_kwh", "Consumul din iulie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      august_kwh: yup.mixed().test("august_kwh", "Consumul din august este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      september_kwh: yup.mixed().test("september_kwh", "Consumul din septembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      october_kwh: yup.mixed().test("october_kwh", "Consumul din octombrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      november_kwh: yup.mixed().test("november_kwh", "Consumul din noiembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
      december_kwh: yup.mixed().test("december_kwh", "Consumul din decembrie este obligatoriu", (value, parent) => {
         const contract_type = parent.options.context.contract_type;
         const locatieFurnizare = parent.options.context.supply_location;
         const tipFurnizare = storageHelpers.getTipFurnizare();

         if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "OTHER") return true;
         if (!value) return false;

         return true;
      }),
   }),

   //    index_gas: yup.string(),document_ci
   //    index_energy: yup.string(),

   //    isProsumer: yup.boolean(),
   //    prosumer_contract_type: yup.string(),
   //    prosumer_voltage: yup.string(),
   //    prosumer_iban: yup.string(),
   //    prosumer_bank: yup.string(),
   //    prosumer_pmax: yup.string(),
   //    prosumer_nrCr: yup.string(),
   //    prosumer_crIssuer: yup.string(),
   //    consumption_production: yup.object().shape({})

   locations: yup.array().of(
      yup.object().shape({
         county_id: yup.mixed().test("county_id", "Județul este obligatoriu", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;

            if (contract_type === "OFERTA_MAIL" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         city_id: yup.mixed().test("city_id", "Localitatea este obligatorie", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;

            if (contract_type === "OFERTA_MAIL" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         street_id: yup.mixed().test("street_id", "Strada este obligatorie", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;

            if (contract_type === "OFERTA_MAIL" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         street_number: yup.mixed().test("street_number", "Numărul este obligatoriu", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;

            if (contract_type === "OFERTA_MAIL" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         //  building: yup.string(),
         //  entrance: yup.string(),
         //  floor: yup.string(),
         //  apartment: yup.string(),
         postal_code: yup.mixed().test("postal_code", "Codul poștal este obligatoriu", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;

            if (contract_type === "OFERTA_MAIL" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         voltage: yup.mixed().test("voltage", "Nivelul de tensiune este obligatoriu", (value, parent) => {
            const contract_type = parent.options.context.contract_type;
            const locatieFurnizare = parent.options.context.supply_location;
            const tipFurnizare = storageHelpers.getTipFurnizare();

            if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
            if (!value) return false;

            return true;
         }),
         consumption_agreement_gas: yup.object().shape({
            january_kwh: yup.mixed().test("january_kwh", "Consumul din ianuarie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            february_kwh: yup.mixed().test("february_kwh", "Consumul din februarie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            march_kwh: yup.mixed().test("march_kwh", "Consumul din martie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            april_kwh: yup.mixed().test("april_kwh", "Consumul din aprilie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            may_kwh: yup.mixed().test("may_kwh", "Consumul din mai este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            june_kwh: yup.mixed().test("june_kwh", "Consumul din iunie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            july_kwh: yup.mixed().test("july_kwh", "Consumul din iulie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            august_kwh: yup.mixed().test("august_kwh", "Consumul din august este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            september_kwh: yup.mixed().test("september_kwh", "Consumul din septembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            october_kwh: yup.mixed().test("october_kwh", "Consumul din octombrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            november_kwh: yup.mixed().test("november_kwh", "Consumul din noiembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            december_kwh: yup.mixed().test("december_kwh", "Consumul din decembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "CURENT" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
         }),
         consumption_agreement_energy: yup.object().shape({
            january_kwh: yup.mixed().test("january_kwh", "Consumul din ianuarie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            february_kwh: yup.mixed().test("february_kwh", "Consumul din februarie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            march_kwh: yup.mixed().test("march_kwh", "Consumul din martie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            april_kwh: yup.mixed().test("april_kwh", "Consumul din aprilie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            may_kwh: yup.mixed().test("may_kwh", "Consumul din mai este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            june_kwh: yup.mixed().test("june_kwh", "Consumul din iunie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            july_kwh: yup.mixed().test("july_kwh", "Consumul din iulie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            august_kwh: yup.mixed().test("august_kwh", "Consumul din august este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            september_kwh: yup.mixed().test("september_kwh", "Consumul din septembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            october_kwh: yup.mixed().test("october_kwh", "Consumul din octombrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            november_kwh: yup.mixed().test("november_kwh", "Consumul din noiembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
            december_kwh: yup.mixed().test("december_kwh", "Consumul din decembrie este obligatoriu", (value, parent) => {
               const contract_type = parent.options.context.contract_type;
               const locatieFurnizare = parent.options.context.supply_location;
               const tipFurnizare = storageHelpers.getTipFurnizare();

               if (contract_type === "OFERTA_MAIL" || tipFurnizare === "GAZ" || locatieFurnizare === "SAME_AS_HOME") return true;
               if (!value) return false;

               return true;
            }),
         }),
         //  index_gas: yup.string(),
         //  index_energy: yup.string(),
      }),
   ),

   gdpr_consent: yup.boolean().oneOf([true], "Trebuie să fii de acord cu termenii și condițiile"),
   //    marketing_consent: yup.boolean(),
});

export const step6ValidationSchema = yup.object().shape({
   // declaration: yup.boolean().oneOf([true], "Trebuie să accepti declaratia"),
   invoice_energy: yup.mixed().test("invoice_energy", "Factura la energie este obligatorie", (value, parent) => {
      // const tipFurnizare = storageHelpers.getTipFurnizare();
      //
      // if (tipFurnizare === "GAZ") return true;
      //
      // if (!value || value.length === 0) return false;
      //
      // if (checkIfEmptyObject(value[0])) return false;

      return true;
   }),
   invoice_gas: yup.mixed().test("invoice_gas", "Factura la gaz este obligatorie", (value, parent) => {
      // const tipFurnizare = storageHelpers.getTipFurnizare();
      //
      // if (tipFurnizare === "CURENT") return true;
      //
      // if (!value || value.length === 0) return false;
      //
      // if (checkIfEmptyObject(value[0])) return false;

      return true;
   }),
   document_ci: yup.mixed().test("document_ci", "Documentul de identitate este obligatoriu", (value, parent) => {
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if ((tipPersoana === "pf" && estePFCuAsociatie) || tipPersoana === "pj") {
         return true;
      } else {
         if (!value || checkIfEmptyObject(value)) {
            return false;
         }
      }

      return true;
   }),
   document_cui: yup.mixed().test("document_cui", "Documentul CUI este obligatoriu", (value, parent) => {
      const tipPersoana = storageHelpers.getTipPersoana();
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      if (tipPersoana === "pf" && !estePFCuAsociatie) {
         return true;
      } else {
         if (!value || checkIfEmptyObject(value)) {
            return false;
         }
      }

      return true;
   }),
});

export const step7ValidationSchema = yup.object().shape({
   signature: yup.string().required("Semnatura este obligatorie"),
});
