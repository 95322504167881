import React from "react";
import { ReactComponent as ICON_CHECK } from "assets/images/confirm-check.svg";

function FinalStep() {
   return (
      <div className="container mt-m-100">
         <div className="row justify-content-center">
            <div className="col-md-12 ml-auto mr-auto text-center  pb-5 pt-3">
               <div className="logo-p7">
                  {/* <img src={require("assets/images/confirm-check.svg")} alt="Logo" height="150px" /> */}
                  <ICON_CHECK color="f02d4b" fill="f02d4b" />
               </div>
               <div className="p-3">
                  <h3 className="mb-4">Mulțumim că ai ales NOVA!</h3>
                  <p className="mb-1 text-muted ">
                     Ai finalizat cu succes procesul de contractare.
                     <br />
                     Vei primi un email de confirmare cu detalii despre pașii următori.
                  </p>
               </div>
            </div>
            <hr className="hr hr-step7 mx-auto" />

            <div className="text-center pt-30">
               <a
                  href="/"
                  className="back-button mt-50 text-decoration-none"
                  onClick={() => {
                     //sterge sessionStorage
                     sessionStorage.removeItem("sessionCurrentStep");
                     sessionStorage.removeItem("sessionStepsState");
                  }}
               >
                  Acasă
               </a>
            </div>
         </div>
      </div>
   );
}

export default FinalStep;
