import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { goToTop } from "helpers/helpers";
import { apiNomenclature } from "api/nomenclature.service";
import { toast } from "react-toastify";
import { step1ValidationSchema } from "schemas/validationSchema";
import objectsSchema from "schemas/objectsSchema";
import CustomSelect from "components/inputs/CustomSelect";
import NavigateButtons from "components/NavigateButtons";
import ServiciiRadio from "components/Step1/ServiciiRadio";
import ConsumatorRadio from "components/Step1/ConsumatorRadio";
import BoxRadio from "components/Step1/BoxRadio";
import { isArray } from "lodash";
export default function Step1({ currentStep, setCurrentStep, setLoading, stepsState, persistState, firstSubmit, setFirstSubmit }) {
   const [optionsCounties, setOptionsCounties] = useState([]);
   const [optionsCities, setOptionsCities] = useState([]);

   const formik = useFormik({
      enableReinitialize: true,
      validateOnChange: firstSubmit,
      validateOnBlur: false,
      initialValues: stepsState,
      validationSchema: step1ValidationSchema,
      onSubmit: async (values, { setSubmitting }) => {
         goToTop();
         setLoading(true);
         await saveData(values);
         setSubmitting(false);
      },
   });

   const modifiedOptionsRadioNivelTensiune =
      formik.values.consumer_type !== "pj" ? objectsSchema.optionsRadioNivelTensiune.slice(0, -1) : objectsSchema.optionsRadioNivelTensiune;

   const formSubmition = e => {
      e.preventDefault();
      setFirstSubmit(true);
      formik.handleSubmit();
   };

   useEffect(() => {
      const getOptionsCounties = async () => {
         const response = await apiNomenclature.getCounties();
         if (isArray(response?.data)) {
            setOptionsCounties(
               response.data.map(item => {
                  return { value: item.county_id, label: item.county };
               }),
            );
         }
      };

      getOptionsCounties();
   }, []);

   useEffect(() => {
      const getOptionsCities = async () => {
         const response = await apiNomenclature.getCitiesByCounty(formik.values.county_id);
         if (response.isSuccess) {
            setOptionsCities(
               response.response.data.map(item => {
                  return { value: item.city_id, label: item.city };
               }),
            );
         } else {
            toast.error(response.error);
            return;
         }
      };

      if (formik.values.county_id) getOptionsCities();
   }, [formik.values.county_id]);

   const saveData = async values => {
      persistState(prevState => {
         return {
            ...prevState,
            step1: {
               ...prevState.step1,
               utility_type: values.utility_type,
               consumer_type: values.consumer_type,
               voltage: values.voltage,
               // consumption_category: values.consumption_category,
               county_id: values.county_id,
               city_id: values.city_id,
               tenant_assoc: values.tenant_assoc,
            },
            // step2: {
            //    ...prevState.step2,
            //    offers: response.response.data,
            // },
            step3: {
               ...prevState.step3,
               voltage: values.voltage,
            },
            step5: {
               ...prevState.step5,
               county_id: values.county_id,
               city_id: values.city_id,
               voltage: values.voltage,
            },
         };
      });
      setCurrentStep(currentStep + 2);
      sessionStorage.setItem("sessionCurrentStep", currentStep + 2);
      setFirstSubmit(false);
      // }
   };

   return (
      <section className="pb-55">
         <div className="container">
            <div className="col-md-9 mx-auto">
               <h2 className="step-title">Află cât poți economisi la energie electrică și gaze naturale</h2>

               <form onSubmit={e => formSubmition(e)}>
                  <ServiciiRadio
                     value={formik.values.utility_type}
                     name={"utility_type"}
                     setFieldValue={formik.setFieldValue}
                     error={formik.errors.utility_type}
                     touched={formik.touched.utility_type}
                  />
                  <ConsumatorRadio
                     value={formik.values.consumer_type}
                     name={"consumer_type"}
                     setFieldValue={formik.setFieldValue}
                     error={formik.errors.consumer_type}
                     touched={formik.touched.consumer_type}
                     voltageValue={formik.values.voltage}
                  />
                  {formik.values.consumer_type === "pf" && (
                     <BoxRadio
                        label={"Sunteți asociație de proprietari?"}
                        value={formik.values.tenant_assoc}
                        name={"tenant_assoc"}
                        setFieldValue={formik.setFieldValue}
                        error={formik.errors.tenant_assoc}
                        touched={formik.touched.tenant_assoc}
                        options={objectsSchema.optionsTenant}
                        optionClassName={"consumption-category-option"}
                     />
                  )}
                  {/* <NivelTensiuneRadio
                     value={formik.values.voltage}
                     name={"voltage"}
                     setFieldValue={formik.setFieldValue}
                     error={formik.errors.voltage}
                     touched={formik.touched.voltage}
                     isNivelTensiuneShown={formik.values.utility_type !== "GAZ"}
                     isPersoanaJuridica={formik.values.consumer_type === "pj"}
                  /> */}
                  {formik.values.utility_type !== "GAZ" && (
                     <BoxRadio
                        label={"Nivel tensiune"}
                        value={formik.values.voltage}
                        name={"voltage"}
                        setFieldValue={formik.setFieldValue}
                        error={formik.errors.voltage}
                        touched={formik.touched.voltage}
                        options={modifiedOptionsRadioNivelTensiune}
                     />
                  )}
                  {/* {(formik.values.utility_type === "GAZ" || formik.values.utility_type === "GAZ_SI_CURENT") && (
                     <BoxRadio
                        label={"Categorie consum"}
                        value={formik.values.consumption_category}
                        name={"consumption_category"}
                        setFieldValue={formik.setFieldValue}
                        error={formik.errors.consumption_category}
                        touched={formik.touched.consumption_category}
                        options={objectsSchema.optionsConsumptionCategory}
                        optionClassName={"consumption-category-option"}
                     />
                  )} */}

                  <CustomSelect
                     options={optionsCounties}
                     value={formik.values.county_id}
                     onChange={value => {
                        formik.setFieldValue("county_id", value.value);
                        formik.setFieldValue("city_id", "");
                        setOptionsCities([]);
                     }}
                     placeholder="Alege județul..."
                     name={"county_id"}
                     label={"Județul de consum"}
                     error={formik.errors.county_id}
                     touched={formik.touched.county_id}
                     parentClassName={"col-md-12"}
                  />
                  <CustomSelect
                     options={optionsCities}
                     value={formik.values.city_id}
                     onChange={value => formik.setFieldValue("city_id", value.value)}
                     placeholder="Alege localitatea..."
                     name={"city_id"}
                     label={"Localitatea de consum"}
                     error={formik.errors.city_id}
                     touched={formik.touched.city_id}
                     parentClassName={"col-md-12 mt-2"}
                  />

                  <NavigateButtons
                     isNextDisabled={formik.isSubmitting}
                     formikErrors={formik.errors}
                     currentStep={currentStep}
                     setCurrentStep={setCurrentStep}
                     back={"display-none"}
                  />
               </form>
            </div>
         </div>
      </section>
   );
}
