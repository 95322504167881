import React, { useState } from "react";
import Select from "react-select";
import { styleHelpers } from "helpers/styleHelpers";

export default function CustomSelect({
   onChange,
   options,
   value,
   placeholder,
   error,
   touched,
   name,
   label,
   parentClassName,
   labelClassName,
   isDisabled = false,
   isSearchable = true,
   styleParent = {},
}) {
   const [menuIsOpen, setMenuIsOpen] = useState(false);

   const defaultValue = (options, value) => {
      return options ? options.find(option => option.value === value) : "";
   };
   const onClickLabel = () => {
      if (isDisabled) {
         return;
      }
      setMenuIsOpen(!menuIsOpen);
   };

   return (
      <div className={parentClassName} style={styleParent}>
         {label && (
            <label onClick={onClickLabel} htmlFor={name} className={`custom-label-title ${labelClassName}`}>
               {label}
            </label>
         )}
         <Select
            id={name}
            name={name}
            classNamePrefix="react-select-form"
            value={value ? defaultValue(options, value) : ""}
            onChange={value => onChange(value)}
            options={options}
            placeholder={placeholder}
            styles={error ? styleHelpers.errorSelectStyle : styleHelpers.normalSelectStyle}
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
         />
         {error && touched && <p className="error-msg">{error}</p>}
      </div>
   );
}
