import axios from "axios";
import { API_URL } from "constants/api.constants";
import { toast } from "react-toastify";
// import AuthService from "api/auth.service";

export const RequestMethod = {
   GET: "Get",
   POST: "Post",
   PUT: "Put",
   PATCH: "Patch",
   DELETE: "Delete",
};

const getErrorMessage = error => {
   if (error?.response?.data?.error) return error.response.data.error;
   else if (error?.message) return error.message;
   else return error;
};

export async function requestJson(path, method, options, hideError = false) {
   try {
      const response = await executeRequest(API_URL, path, method, options);
      console.log("🚀 ~ requestJson ~ response:", response);

      return { response: response, isSuccess: true, error: null };
   } catch (ex) {
      if (!hideError) {
         const errorMessage = getErrorMessage(ex);
         toast.error(errorMessage);
      }

      return { response: null, isSuccess: false, error: ex?.message ?? ex, status: ex?.response?.status };
   }
}

async function executeRequest(baseUrl, path, method, options) {
   const url = baseUrl + path;
   const headers = getHeaders(options);
   let response;
   switch (method) {
      case RequestMethod.GET:
         response = await axios.get(jsonToUrl(url, options?.queryStringParams), {
            headers: headers,
         });
         break;
      case RequestMethod.POST:
         response = await axios.post(jsonToUrl(url, options?.queryStringParams), options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.PUT:
         response = await axios.put(jsonToUrl(url, options?.queryStringParams), options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.PATCH:
         response = await axios.patch(jsonToUrl(url, options?.queryStringParams), options?.data, {
            headers: headers,
         });
         break;
      case RequestMethod.DELETE:
         response = await axios.delete(jsonToUrl(url, options?.queryStringParams), { headers: headers });
         break;
      default:
         break;
   }
   return response?.data;
}

function getHeaders(options) {
   let headers = {};

   if (options?.useAccessToken) {
      const user = "AuthService.getCurrentUser();"; // user = AuthService.getCurrentUser();
      headers["Authorization"] = `Bearer ${user.token}`;
   }

   if (options?.headers) {
      options.headers.forEach(header => {
         headers[header.name] = header.value;
      });
   }

   return headers;
}

function jsonToUrl(url, data) {
   if (data) {
      const urlData = Object.keys(data)
         .map(k => {
            if (data[k] === undefined) return;
            if (Array.isArray(data[k])) {
               const result = data[k].map(x => encodeURIComponent(k) + "=" + encodeURIComponent(x)).join("&");
               return result;
            }
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
         })
         .join("&");

      if (urlData === undefined && urlData === "") return url;
      return `${url}?${urlData}`;
   }

   return url;
}
