import React, { useState } from "react";

export default function BoxRadio({ label, value, name, setFieldValue, error, touched, options, optionClassName }) {
   const [nivelTensiune, setNivelTensiune] = useState(null);

   const getServiciiCardClassName = (nivelTensiune, selected) => {
      if (nivelTensiune === null && value === selected) return `nivelTensiune-option nivelTensiune-option-selected ${optionClassName}`;
      if (selected === nivelTensiune) return `nivelTensiune-option nivelTensiune-option-selected ${optionClassName}`;
      else return `nivelTensiune-option ${optionClassName}`;
   };

   const handleClick = selected => {
      setNivelTensiune(selected);
      setFieldValue(name, selected);
   };

   return (
      <>
         <div id={name} className="mx-auto row nivelTensiune-container">
            <label className="custom-label-title">{label}</label>
            <div className="nivelTensiune-options-container">
               {options.map((option, index) => (
                  <div key={index} onClick={() => handleClick(option.value)} className={getServiciiCardClassName(nivelTensiune, option.value)}>
                     <div className="nivelTensiune-box" />
                     <p className="nivelTensiune-text">{option.label}</p>
                  </div>
               ))}
            </div>
         </div>
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
