import React from "react";

const CheckboxInput = ({ title, label, value, name, error, touched, handleChange, parentClassName }) => {
   return (
      <div className={`${parentClassName}`}>
         {title && (
            <label className="checkbox-title" htmlFor={name}>
               {title}
            </label>
         )}
         <div className="checkbox-wrapper-13 d-flex align-items-center">
            <input className="checkbox-input" type="checkbox" name={name} id={name} defaultChecked={value} value={value} onChange={handleChange} />
            <label htmlFor={name} className="ms-2 checkbox-label">
               {label}
            </label>
         </div>
         {error && touched && <p className="error-msg">{error}</p>}
      </div>
   );
};

export default CheckboxInput;
