import React from "react";
import TextInput from "components/inputs/TextInput";
import DatePickerCustom from "components/inputs/DatePicker";

export default function SectionDatePersonale({ formik, consumer_type, tenant_assoc, isUsedInOpenTicketModal = false }) {
   return (
      <>
         <h2 className="custom-label-title">Date personale</h2>
         <div className="row mb-25">
            <TextInput
               name={"last_name"}
               label={"Nume"}
               value={formik.values.last_name}
               isDisabled={isUsedInOpenTicketModal}
               error={formik.errors.last_name}
               touched={formik.touched.last_name}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-6 input-row"}
            />
            <TextInput
               name={"first_name"}
               label={"Prenume"}
               value={formik.values.first_name}
               error={formik.errors.first_name}
               isDisabled={isUsedInOpenTicketModal}
               touched={formik.touched.first_name}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-6"}
            />
         </div>
         {consumer_type === "pf" && tenant_assoc === "0" && (
            <>
               <div className="row mb-25">
                  <TextInput
                     name={"ci_cnp"}
                     label={"CNP"}
                     value={formik.values.ci_cnp}
                     isDisabled={isUsedInOpenTicketModal}
                     error={formik.errors.ci_cnp}
                     touched={formik.touched.ci_cnp}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-12 mb-25"}
                     maxLengthText={13}
                  />
               </div>
               <div className="row mb-25">
                  <TextInput
                     name={"ci_sn"}
                     label={"Serie CI"}
                     value={formik.values.ci_sn}
                     error={formik.errors.ci_sn}
                     isDisabled={isUsedInOpenTicketModal}
                     touched={formik.touched.ci_sn}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-6 input-row"}
                     maxLengthText={2}
                  />
                  <TextInput
                     name={"ci_number"}
                     label={"Numar CI"}
                     value={formik.values.ci_number}
                     isDisabled={isUsedInOpenTicketModal}
                     error={formik.errors.ci_number}
                     touched={formik.touched.ci_number}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-6"}
                     maxLengthText={6}
                  />
               </div>
               <div className="row mb-25">
                  <TextInput
                     name={"ci_issuer"}
                     label={"Emis de"}
                     value={formik.values.ci_issuer}
                     error={formik.errors.ci_issuer}
                     isDisabled={isUsedInOpenTicketModal}
                     touched={formik.touched.ci_issuer}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-6 input-row"}
                  />
                  <DatePickerCustom
                     containerClassName="col-md-6"
                     wrapperClassName="ci_date-wrapper"
                     pickerClassName="ci_date-picker"
                     placeholderText="La data"
                     label={"La data"}
                     id={"ci_date"}
                     objectRoute={"ci_date"}
                     setFieldValue={formik.setFieldValue}
                     value={formik.values.ci_date}
                     error={formik.errors.ci_date}
                     isDisabled={isUsedInOpenTicketModal}
                     touched={formik.touched.ci_date}
                     maxDate={new Date()}
                  />
               </div>
            </>
         )}
      </>
   );
}
