import { useEffect } from "react";
import { apiSteps } from "api/steps.service";
import { isObject } from "lodash";

let maxRetries = 10;
export function useFetchPdfsWithRetry(stepsState, actions, retry = false, setIsFetchingDocs = () => {}) {
   useEffect(() => {
      const fetchData = async () => {
         if (isObject(stepsState?.step7?.pdfs)) {
            console.warn("Pdfs already received.");
            actions.actionsForSuccess(stepsState.step7.pdfs);
            setIsFetchingDocs(false);
            return;
         }
         // const stepsState = storageHelpers.getStateStep6();

         //check if pdf is already there

         if (!stepsState?.utility_type || !stepsState?.lead_object || !stepsState?.consumer_type) {
            actions.actionsForFail();
            setIsFetchingDocs(false);
            return;
         }

         const payload = {
            utility_type: stepsState?.utility_type,
            lead_object: stepsState?.lead_object,
            consumer_type: stepsState?.consumer_type,
         };
         setIsFetchingDocs(true);
         await apiSteps.fetchPdfsWithRetry(maxRetries, payload, actions);
      };
      //check if pdf is already there

      fetchData();
   }, [retry]);
}
