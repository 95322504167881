import Header from "components/Header";
import React, { useState, useEffect } from "react";
import Step1 from "pages/Steps/Step1";
import Step3 from "pages/Steps/Step3";
import Step4 from "pages/Steps/Step4";
import Step5 from "pages/Steps/Step5";
import Step6 from "pages/Steps/Step6";
import Step7 from "pages/Steps/Step7";
import objectsSchema from "schemas/objectsSchema";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScreenLoading from "components/ScreenLoading";
import FinalStep from "./Steps/FinalStep";
import FinalStepOfertaMail from "pages/Steps/FinalStepOfertaMail";
import prepareData from "schemas/prepareDataSchema";
export default function ContractForm() {
   const [currentStep, setCurrentStep] = useState(1);
   const [loading, setLoading] = useState(false);
   const [firstSubmit, setFirstSubmit] = useState(false);

   const [stepsState, setAllStepsState] = useState({
      lead_object: {},
      step1: { utility_type: "GAZ_SI_CURENT", consumer_type: "pf", voltage: "low", county_id: "", city_id: "", tenant_assoc: "0" },
      step2: { offers: [] },
      step3: {
         surface_area: "25",
         estimated_energy: "75",
         estimated_gas: "250",
         monthly_consumption_energy: "", // NU_SE_TRIMITE_IN_API
         monthly_consumption_gas: "", // NU_SE_TRIMITE_IN_API
         monthly_consumption_gas_mc: "",
         provider_id_gas: "",
         provider_id_energy: "",
      },
      step4: { offers: [], selectedOffers: { energy: null, gas: null } },
      step5: {
         contract_type: "CONTRACTARE_ONLINE",
         first_name: "",
         last_name: "",
         email: "",
         gdpr_consent: true, // NU_SE_TRIMITE_IN_API
         marketing_consent: false, // NU_SE_TRIMITE_IN_API
         ci_cnp: "", // consumer_type === pf
         ci_sn: "", // consumer_type === pf
         ci_number: "", // consumer_type === pf
         ci_issuer: "", // consumer_type === pf
         ci_date: "", // consumer_type === pf
         phone_number: "",

         company_name: "", // NU_SE_TRIMITE_IN_API consumer_type === pj
         company_cif: "", // NU_SE_TRIMITE_IN_API consumer_type === pj
         company_bank_account: "", // NU_SE_TRIMITE_IN_API consumer_type === pj
         company_bank_id: "", // NU_SE_TRIMITE_IN_API consumer_type === pj

         county_id: "",
         city_id: "",
         street_id: "",
         street_number: "",
         building: "",
         entrance: "",
         floor: "",
         apartment: "",
         postal_code: "",
         voltage: "", // NU_SE_TRIMITE_IN_API
         consumption_agreement_gas: objectsSchema.consumption_agreement,
         consumption_agreement_energy: objectsSchema.consumption_agreement,
         switchGas: false,
         switchEnergy: false,
         unitMeasureGas: "kwh",
         unitMeasureEnergy: "kwh",
         isInit: false,
         index_gas: "", // NU_SE_TRIMITE_IN_API
         index_energy: "", // NU_SE_TRIMITE_IN_API
         isProsumer: false, // NU_SE_TRIMITE_IN_API
         // prosumer_contract_type: "0-200", // NU_SE_TRIMITE_IN_API
         // prosumer_voltage: "low", // NU_SE_TRIMITE_IN_API
         // prosumer_iban: "", // NU_SE_TRIMITE_IN_API
         // prosumer_bank: "", // NU_SE_TRIMITE_IN_API
         // prosumer_pmax: "", // NU_SE_TRIMITE_IN_API
         // prosumer_nrCr: "", // NU_SE_TRIMITE_IN_API
         // prosumer_crIssuer: "", // NU_SE_TRIMITE_IN_API
         // consumption_production: objectsSchema.consumption_production, // NU_SE_TRIMITE_IN_API
         supply_location: "SAME_AS_HOME", // NU_SE_TRIMITE_IN_API
         locations: [
            {
               county_id: "",
               city_id: "",
               street_id: "",
               street_number: "",
               building: "",
               entrance: "",
               floor: "",
               apartment: "",
               postal_code: "",
               voltage: "", // NU_SE_TRIMITE_IN_API
               consumption_agreement_gas: objectsSchema.consumption_agreement,
               consumption_agreement_energy: objectsSchema.consumption_agreement,
               switchGas: false,
               switchEnergy: false,
               unitMeasureGas: "kwh",
               unitMeasureEnergy: "kwh",
               index_gas: "", // NU_SE_TRIMITE_IN_API
               index_energy: "", // NU_SE_TRIMITE_IN_API
               isProsumer: false, // NU_SE_TRIMITE_IN_API
               // prosumer_contract_type: "0-200", // NU_SE_TRIMITE_IN_API
               // prosumer_voltage: "low", // NU_SE_TRIMITE_IN_API
               // prosumer_iban: "", // NU_SE_TRIMITE_IN_API
               // prosumer_bank: "", // NU_SE_TRIMITE_IN_API
               // prosumer_pmax: "", // NU_SE_TRIMITE_IN_API
               // prosumer_nrCr: "", // NU_SE_TRIMITE_IN_API
               // prosumer_crIssuer: "", // NU_SE_TRIMITE_IN_API
               // consumption_production: objectsSchema.consumption_production, // NU_SE_TRIMITE_IN_API
            },
         ],
      },
      step6: { declaration: false, invoice_energy: [], invoice_gas: [], document_ci: {}, document_cui: {} },
      step7: { pdfs: null, signature: "" },
      step8: {},
   });

   //functie care salveaza si seteaza state-ul pentru fiecare pas
   const persistState = async funct => {
      const prevState = stepsState;
      const data = funct(prevState);
      sessionStorage.setItem("sessionStepsState", JSON.stringify(data));
      setAllStepsState(funct);
   };

   //get the step after refresh
   useEffect(() => {
      const sessionStepsState = JSON.parse(sessionStorage.getItem("sessionStepsState"));
      const step = parseInt(sessionStorage.getItem("sessionCurrentStep"));

      if (sessionStorage.getItem("sessionCurrentStep") !== null) setCurrentStep(step);
      if (sessionStepsState) setAllStepsState(sessionStepsState);
   }, []);

   //daca loading === true nu poti face scroll
   if (loading) document.body.style.overflow = "hidden";
   else document.body.style.overflow = "auto";

   return (
      <>
         <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
         />
         {loading && <ScreenLoading />}
         <Header currentStep={currentStep} />

         {(() => {
            switch (currentStep) {
               case 1:
                  return (
                     <Step1
                        stepsState={stepsState.step1}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoading={setLoading}
                        firstSubmit={firstSubmit}
                        setFirstSubmit={setFirstSubmit}
                     />
                  );
               // case 2:
               //    return (
               //       <Step2
               //          stepsState={{ consumer_type: stepsState.step1.consumer_type, step2: stepsState.step2 }}
               //          persistState={persistState}
               //          currentStep={currentStep}
               //          setCurrentStep={setCurrentStep}
               //       />
               //    );
               case 3:
                  return (
                     <Step3
                        stepsState={{
                           consumer_type: stepsState.step1.consumer_type,
                           utility_type: stepsState.step1.utility_type,
                           county_id: stepsState.step1.county_id,
                           tenant_assoc: stepsState.step1.tenant_assoc,
                           // consumption_category: stepsState.step1.consumption_category,
                           step3: stepsState.step3,
                        }}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoading={setLoading}
                        firstSubmit={firstSubmit}
                        setFirstSubmit={setFirstSubmit}
                     />
                  );
               case 4:
                  return (
                     <Step4
                        consumer_type={stepsState.step1.consumer_type}
                        stepsState={stepsState.step4}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                     />
                  );
               case 5:
                  return (
                     <Step5
                        stepsState={{
                           consumer_type: stepsState.step1.consumer_type,
                           tenant_assoc: stepsState.step1.tenant_assoc,
                           selectedOffers: stepsState.step4.selectedOffers,
                           estimated_energy: stepsState.step3.estimated_energy,
                           estimated_gas: stepsState.step3.estimated_gas,
                           monthly_consumption_energy: stepsState.step3.monthly_consumption_energy,
                           monthly_consumption_gas: stepsState.step3.monthly_consumption_gas,
                           utility_type: stepsState.step1.utility_type,
                           surface_area: stepsState.step3.surface_area,
                           lead_object: stepsState.lead_object,
                           step5: {
                              ...stepsState.step5,
                              ...prepareData.prepareValuesLead(stepsState.step5, true, stepsState.step1.consumer_type),
                              isInit: stepsState.step5.isInit,
                              switchGas: stepsState.step5.isInit ? stepsState.step5.switchGas : stepsState.step1.consumer_type === "pj",
                              switchEnergy: stepsState.step5.isInit ? stepsState.step5.switchEnergy : stepsState.step1.consumer_type === "pj",
                              unitMeasureGas: stepsState.step5.isInit
                                 ? stepsState.step5.unitMeasureGas
                                 : stepsState.step1.consumer_type === "pj"
                                   ? "mwh"
                                   : "kwh",
                              unitMeasureEnergy: stepsState.step5.isInit
                                 ? stepsState.step5.unitMeasureEnergy
                                 : stepsState.step1.consumer_type === "pj"
                                   ? "mwh"
                                   : "kwh",
                           },
                           step3: stepsState.step3,
                           step1: stepsState.step1,
                        }}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoading={setLoading}
                        firstSubmit={firstSubmit}
                        setFirstSubmit={setFirstSubmit}
                     />
                  );
               case 6:
                  return (
                     <Step6
                        stepsState={{
                           tenant_assoc: stepsState.step1.tenant_assoc,
                           utility_type: stepsState.step1.utility_type,
                           consumer_type: stepsState.step1.consumer_type,
                           first_name: stepsState.step5.first_name,
                           last_name: stepsState.step5.last_name,
                           lead_object: stepsState.lead_object,
                           step6: stepsState.step6,
                           step7: stepsState.step7,
                        }}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoading={setLoading}
                        firstSubmit={firstSubmit}
                        setFirstSubmit={setFirstSubmit}
                     />
                  );

               case 7:
                  return (
                     <Step7
                        stepsState={{
                           utility_type: stepsState.step1.utility_type,
                           lead_object: stepsState.lead_object,
                           consumer_type: stepsState.step1.consumer_type,

                           step7: stepsState.step7,
                        }}
                        persistState={persistState}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoading={setLoading}
                        firstSubmit={firstSubmit}
                        setFirstSubmit={setFirstSubmit}
                     />
                  );
               case 8:
                  return <FinalStep />;
               case 9:
                  return <FinalStepOfertaMail />;
               default:
                  return <Step1 />;
            }
         })()}
      </>
   );
}
