const optionsTensiune = [
   { value: "low", label: "Joasa tensiune" },
   { value: "medium", label: "Medie tensiune" },
   { value: "high", label: "Inalta tensiune" },
];
const optionsTipLocuinta = [
   { value: "25", label: "Garsonieră ~ 25 m²" },
   { value: "40", label: "Ap. 1 cameră ~ 40 m²" },
   { value: "60", label: "Ap. 2 camere ~ 60 m²" },
   { value: "90", label: "Ap. 3 camere ~ 90 m²" },
   { value: "100", label: "Ap. 4 camere ~ 100 m²" },
   { value: "130", label: "Casă mică ~ 130 m²" },
   { value: "200", label: "Casă obișnuită ~ 200 m²" },
];
const optionsRadioNivelTensiune = [
   {
      label: "Joasa tensiune",
      value: "low",
   },
   {
      label: "Medie tensiune",
      value: "medium",
   },
   {
      label: "Inalta tensiune",
      value: "high",
   },
];
const optionsConsumptionCategory = [
   {
      label: "A1",
      value: "A1",
   },
   {
      label: "B1",
      value: "B1",
   },
   {
      label: "C1",
      value: "C1",
   },
   {
      label: "C2",
      value: "C2",
   },
   {
      label: "C3",
      value: "C3",
   },
   {
      label: "C4",
      value: "C4",
   },
   {
      label: "C5",
      value: "C5",
   },
];

const optionsTenant = [
   {
      label: "Nu",
      value: "0",
   },
   {
      label: "Da",
      value: "1",
   },
];

const consumption_agreement = {
   january_kwh: "0",
   february_kwh: "0",
   march_kwh: "0",
   april_kwh: "0",
   may_kwh: "0",
   june_kwh: "0",
   july_kwh: "0",
   august_kwh: "0",
   september_kwh: "0",
   october_kwh: "0",
   november_kwh: "0",
   december_kwh: "0",
};

const months_convention = [
   { label: "Ianuarie", key: "january" },
   { label: "Febuarie", key: "february" },
   { label: "Martie", key: "march" },
   { label: "Aprilie", key: "april" },
   { label: "Mai", key: "may" },
   { label: "Iunie", key: "june" },
   { label: "Iulie", key: "july" },
   { label: "August", key: "august" },
   { label: "Septembrie", key: "september" },
   { label: "Octombrie", key: "october" },
   { label: "Noiembrie", key: "november" },
   { label: "Decembrie", key: "december" },
];

const contract_type_radio = [
   {
      value: "0-200",
      label: "0-200KWh",
   },
   {
      value: "200-400",
      label: "200-400KWh",
   },
];

const voltage_radio = [
   {
      value: "low",
      label: "Joasa",
   },
   {
      value: "medium",
      label: "Medie",
   },
];

const consumption_production = {
   january_kwh: "Cf. PV.",
   february_kwh: "Cf. PV.",
   march_kwh: "Cf. PV.",
   april_kwh: "Cf. PV.",
   may_kwh: "Cf. PV.",
   june_kwh: "Cf. PV.",
   july_kwh: "Cf. PV.",
   august_kwh: "Cf. PV.",
   september_kwh: "Cf. PV.",
   october_kwh: "Cf. PV.",
   november_kwh: "Cf. PV.",
   december_kwh: "Cf. PV.",
};

const months = [
   "january_kwh",
   "february_kwh",
   "march_kwh",
   "april_kwh",
   "may_kwh",
   "june_kwh",
   "july_kwh",
   "august_kwh",
   "september_kwh",
   "october_kwh",
   "november_kwh",
   "december_kwh",
];

const supply_location = [
   {
      value: "SAME_AS_HOME",
      label: "Aceeași adresă cu domiciliul",
   },
   {
      value: "OTHER",
      label: "Altă adresă",
   },
];

const locationsSchema = {
   county_id: "",
   city_id: "",
   street_id: "",
   street_number: "",
   building: "",
   entrance: "",
   floor: "",
   apartment: "",
   postal_code: "",
   voltage: "", // NU_SE_TRIMITE_IN_API
   consumption_agreement_gas: consumption_agreement,
   consumption_agreement_energy: consumption_agreement,

   index_gas: "", // NU_SE_TRIMITE_IN_API
   index_energy: "", // NU_SE_TRIMITE_IN_API
   isProsumer: false, // NU_SE_TRIMITE_IN_API
   // prosumer_contract_type: "0-200", // NU_SE_TRIMITE_IN_API
   // prosumer_voltage: "low", // NU_SE_TRIMITE_IN_API
   // prosumer_iban: "", // NU_SE_TRIMITE_IN_API
   // prosumer_bank: "", // NU_SE_TRIMITE_IN_API
   // prosumer_pmax: "", // NU_SE_TRIMITE_IN_API
   // prosumer_nrCr: "", // NU_SE_TRIMITE_IN_API
   // prosumer_crIssuer: "", // NU_SE_TRIMITE_IN_API
   // consumption_production: consumption_production, // NU_SE_TRIMITE_IN_API
};

const objectsSchema = {
   optionsTensiune,
   optionsTipLocuinta,
   optionsRadioNivelTensiune,
   optionsConsumptionCategory,
   consumption_agreement,
   months_convention,
   contract_type_radio,
   voltage_radio,
   consumption_production,
   supply_location,
   locationsSchema,
   months,
   optionsTenant,
};

export default objectsSchema;
