import { format } from "date-fns";
import { getFileExtension, getNumberFromString, verifyToNotSaveToStorage } from "helpers/helpers";
import objectsSchema from "schemas/objectsSchema";
import { storageHelpers } from "helpers/storageHelpers";

const step2Offers = data => {
   const { utility_type, consumer_type, voltage, county_id, tenant_assoc } = data;
   const tenant_assocToPost = tenant_assoc === "0" ? 0 : 1;

   const buildEnergyOffer = () => {
      return {
         utility_type: "energy",
         consumer_type,
         voltage: voltage,
         county_id,
         ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
      };
   };
   const buildGasOffer = () => {
      return {
         utility_type: "gas",
         consumer_type,
         county_id,
         ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
      };
   };

   switch (utility_type) {
      case "GAZ_SI_CURENT":
         return [buildEnergyOffer(), buildGasOffer()];
      case "CURENT":
         return [buildEnergyOffer()];
      case "GAZ":
         return [buildGasOffer()];
      default:
         return [];
   }
};

const getProviders = (utility_type, county_id) => {
   if (utility_type === "GAZ_SI_CURENT") {
      return [
         {
            utility_type: "energy",
            county_id,
         },
         {
            utility_type: "gas",
            county_id,
         },
      ];
   }

   if (utility_type === "CURENT") {
      return [
         {
            utility_type: "energy",
            county_id,
         },
      ];
   }

   if (utility_type === "GAZ") {
      return [
         {
            utility_type: "gas",
            county_id,
         },
      ];
   }
};
const transformForPJInKWh = (value, isPJ) => {
   if (isPJ) {
      return String(value * 1000);
   }
   return String(value);
};
const step4Offers = data => {
   const {
      utility_type,
      consumer_type,
      voltage,
      provider_id_gas,
      provider_id_energy,
      estimated_gas,
      estimated_energy,
      monthly_consumption_energy,
      monthly_consumption_gas,
      tenant_assoc,
   } = data;
   const tenant_assocToPost = tenant_assoc === "0" ? 0 : 1;

   const isPJ = consumer_type === "pj";

   if (utility_type === "GAZ_SI_CURENT") {
      return [
         {
            utility_type: "energy",
            consumer_type,
            voltage: voltage,
            provider_id: provider_id_energy,
            consumption:
               monthly_consumption_energy !== ""
                  ? transformForPJInKWh(monthly_consumption_energy, isPJ)
                  : transformForPJInKWh(estimated_energy, isPJ),
            ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
         },
         {
            utility_type: "gas",
            consumer_type,
            provider_id: provider_id_gas,
            consumption:
               monthly_consumption_gas !== "" ? transformForPJInKWh(monthly_consumption_gas, isPJ) : transformForPJInKWh(estimated_gas, isPJ),
            ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
         },
      ];
   }
   if (utility_type === "GAZ") {
      return [
         {
            utility_type: "gas",
            consumer_type,
            provider_id: provider_id_gas,
            consumption:
               monthly_consumption_gas !== "" ? transformForPJInKWh(monthly_consumption_gas, isPJ) : transformForPJInKWh(estimated_gas, isPJ),
            ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
         },
      ];
   }
   if (utility_type === "CURENT") {
      return [
         {
            utility_type: "energy",
            consumer_type,
            voltage: voltage,
            provider_id: provider_id_energy,
            consumption:
               monthly_consumption_energy !== ""
                  ? transformForPJInKWh(monthly_consumption_energy, isPJ)
                  : transformForPJInKWh(estimated_energy, isPJ),
            ...(consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
         },
      ];
   }
};

const prepareLeadNew = (values, tenantAssoc, selectedOffers, estimated_energy, estimated_gas, provider_id_energy, provider_id_gas) => {
   const { utility_type, locations, supply_location, lead_object, consumer_type } = values;

   const returnedMeteringPoints = type => {
      const getValuesForConsumptionAgreement = (type, index, isInitial) => {
         const transformConsumptionAgreementInKWh = (agreeement = {}) => {
            let agreeementInKWh = {};

            for (const [key, value] of Object.entries(agreeement)) {
               agreeementInKWh[key] = transformForPJInKWh(String(Number(value), consumer_type === "pj"));
            }
            return agreeementInKWh;
         };
         if (type === "energy")
            return {
               ...{
                  id: lead_object?.energy?.MeteringPoints[index]?.consumption_agreement_id
                     ? lead_object?.energy?.MeteringPoints[index]?.consumption_agreement_id
                     : "",
               },
               ...transformConsumptionAgreementInKWh(values.consumption_agreement_energy),
            };
         else
            return {
               ...{
                  id: lead_object?.gas?.MeteringPoints[index]?.consumption_agreement_id
                     ? lead_object?.gas?.MeteringPoints[index]?.consumption_agreement_id
                     : "",
               },
               ...transformConsumptionAgreementInKWh(values.consumption_agreement_gas),
            };
      };

      const getMeteringPointId = (type, index) => {
         if (type === "energy")
            return lead_object?.energy?.MeteringPoints[index]?.MeteringPointId ? lead_object?.energy?.MeteringPoints[index]?.MeteringPointId : "";
         else return lead_object?.gas?.MeteringPoints[index]?.MeteringPointId ? lead_object?.gas?.MeteringPoints[index]?.MeteringPointId : "";
      };
      const getIndexValue = (type, index) => {
         if (index === "INITIAL") {
            if (type === "energy") return values.index_energy;
            else return values.index_gas;
         } else {
            if (type === "energy") return values.locations?.[index]?.index_energy;
            else return values.locations?.[index]?.index_gas;
         }
      };

      // daca e o singura locatie se trimite doar o locatie cu index 0,
      // daca sunt mai multe locatii se trimite o locatie cu index 0(adresa de domiciliu)
      // si restul locatiilor cu index 1,2,3...
      if (supply_location === "SAME_AS_HOME") {
         return [
            {
               id: getMeteringPointId(type, 0) ? getMeteringPointId(type, 0) : "",
               county_id: values.county_id,
               surface_area: values.surface_area,
               city_id: values.city_id,
               street_id: values.street_id,
               street_number: values.street_number,
               building: values.building,
               entrance: values.entrance,
               floor: values.floor,
               apartment: values.apartment,
               postal_code: values.postal_code,
               prosumer: values.isProsumer ? 1 : 0,
               voltage: values.voltage,
               index: getNumberFromString(getIndexValue(type, "INITIAL")),
               consumption_agreement: getValuesForConsumptionAgreement(type, 0, true),
            },
         ];
      } else {
         return [
            {
               // id: getMeteringPointId(type, 0),
               county_id: values.county_id,
               surface_area: values.surface_area,
               city_id: values.city_id,
               street_id: values.street_id,
               street_number: values.street_number,
               building: values.building,
               entrance: values.entrance,
               floor: values.floor,
               apartment: values.apartment,
               postal_code: values.postal_code,
               index: "0", //trimitem pentru ca exista validare in API
               prosumer: values.isProsumer ? 1 : 0, //trimitem pentru ca exista validare in API
               voltage: values.voltage, //trimitem pentru ca exista validare in API
               consumption_agreement: {
                  //trimitem pentru ca exista validare in API
                  id: "",
                  ...objectsSchema.consumption_agreement,
               },
            },
            ...locations.map((location, index) => {
               return {
                  id: getMeteringPointId(type, index) ? getMeteringPointId(type, index) : "",
                  county_id: location.county_id,
                  surface_area: values.surface_area,
                  city_id: location.city_id,
                  street_id: location.street_id,
                  street_number: location.street_number,
                  building: location.building,
                  entrance: location.entrance,
                  floor: location.floor,
                  apartment: location.apartment,
                  postal_code: location.postal_code,
                  prosumer: values.isProsumer ? 1 : 0,
                  voltage: location.voltage,
                  index: getNumberFromString(getIndexValue(type, index)),
                  consumption_agreement: getValuesForConsumptionAgreement(type, index, false),
               };
            }),
         ];
      }
   };

   if (utility_type === "GAZ_SI_CURENT") {
      return [
         {
            id: lead_object?.energy?.id ? lead_object?.energy?.id : "",
            tenant_assoc: tenantAssoc,
            offer_id: selectedOffers.energy,
            consumption: parseInt(transformForPJInKWh(estimated_energy, consumer_type === "pj")),
            consumer_type: values.consumer_type,
            first_name: values.first_name,
            last_name: values.last_name,
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_cnp: values.ci_cnp } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_sn: values.ci_sn + values.ci_number } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_issuer: values.ci_issuer } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_date: format(new Date(values.ci_date), "yyyy-MM-dd") } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_name: values.company_name } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_cif: values.company_cif } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_account: values.company_bank_account } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_id: values.company_bank_id } : {}),
            email: values.email,
            phone_number: values.phone_number,
            gdpr_consent: values.gdpr_consent ? 1 : 0,
            marketing_consent: values.marketing_consent ? 1 : 0,
            prosumer: values.isProsumer ? 1 : 0,
            utility_type: "energy",
            provider_id: provider_id_energy,

            metering_points: returnedMeteringPoints("energy"),
         },
         {
            id: lead_object?.gas?.id ? lead_object?.gas?.id : "",
            tenant_assoc: tenantAssoc,
            offer_id: selectedOffers.gas,
            consumption: parseInt(transformForPJInKWh(estimated_gas, consumer_type === "pj")),
            consumer_type: values.consumer_type,
            first_name: values.first_name,
            last_name: values.last_name,
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_cnp: values.ci_cnp } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_sn: values.ci_sn + values.ci_number } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_issuer: values.ci_issuer } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_date: format(new Date(values.ci_date), "yyyy-MM-dd") } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_name: values.company_name } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_cif: values.company_cif } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_account: values.company_bank_account } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_id: values.company_bank_id } : {}),
            email: values.email,
            phone_number: values.phone_number,
            gdpr_consent: values.gdpr_consent ? 1 : 0,
            marketing_consent: values.marketing_consent ? 1 : 0,
            prosumer: values.isProsumer ? 1 : 0,
            utility_type: "gas",
            provider_id: provider_id_gas,
            metering_points: returnedMeteringPoints("gas"),
         },
      ];
   }
   if (utility_type === "GAZ") {
      return [
         {
            id: lead_object?.gas?.id ? lead_object?.gas?.id : "",
            tenant_assoc: tenantAssoc,
            offer_id: selectedOffers.gas,
            consumption: parseInt(transformForPJInKWh(estimated_gas, consumer_type === "pj")),
            consumer_type: values.consumer_type,
            first_name: values.first_name,
            last_name: values.last_name,
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_cnp: values.ci_cnp } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_sn: values.ci_sn + values.ci_number } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_issuer: values.ci_issuer } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_date: format(new Date(values.ci_date), "yyyy-MM-dd") } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_name: values.company_name } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_cif: values.company_cif } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_account: values.company_bank_account } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_id: values.company_bank_id } : {}),
            email: values.email,
            phone_number: values.phone_number,
            gdpr_consent: values.gdpr_consent ? 1 : 0,
            marketing_consent: values.marketing_consent ? 1 : 0,
            prosumer: values.isProsumer ? 1 : 0,
            utility_type: "gas",
            provider_id: provider_id_gas,
            metering_points: returnedMeteringPoints("gas"),
         },
      ];
   }
   if (utility_type === "CURENT") {
      return [
         {
            id: lead_object?.energy?.id ? lead_object?.energy?.id : "",
            tenant_assoc: tenantAssoc,
            offer_id: selectedOffers.energy,
            consumption: parseInt(transformForPJInKWh(estimated_energy, consumer_type === "pj")),
            consumer_type: values.consumer_type,
            first_name: values.first_name,
            last_name: values.last_name,
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_cnp: values.ci_cnp } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_sn: values.ci_sn + values.ci_number } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_issuer: values.ci_issuer } : {}),
            ...(consumer_type === "pf" && tenantAssoc === "0" ? { ci_date: format(new Date(values.ci_date), "yyyy-MM-dd") } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_name: values.company_name } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_cif: values.company_cif } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_account: values.company_bank_account } : {}),
            ...(consumer_type === "pj" || tenantAssoc === "1" ? { company_bank_id: values.company_bank_id } : {}),
            email: values.email,
            phone_number: values.phone_number,
            gdpr_consent: values.gdpr_consent ? 1 : 0,
            marketing_consent: values.marketing_consent ? 1 : 0,
            prosumer: values.isProsumer ? 1 : 0,
            utility_type: "energy",
            provider_id: provider_id_energy,
            metering_points: returnedMeteringPoints("energy"),
         },
      ];
   }
};

const upload = async values => {
   const { utility_type, consumer_type, lead_object } = values;
   const gasId = lead_object?.gas?.id;
   const energyId = lead_object?.energy?.id;
   const formData = new FormData();

   const appendToFormData = async (type, id, invoices, documentCi, documentCui) => {
      const estePFCuAsociatie = storageHelpers.estePFCuAsociatie();

      for (let i = 0; i < invoices.length; i++) {
         const invoiceToPost = await verifyToNotSaveToStorage(invoices[i]);
         const invoiceExtension = getFileExtension(invoiceToPost);
         formData.append(`files[${id}][${i}]`, invoiceToPost, `factura_${type}_${i + 1}.${invoiceExtension}`);
      }
      const indexOfId = invoices.length - 1;

      if (!estePFCuAsociatie && consumer_type === "pf" && documentCi) {
         const documentCiToPost = await verifyToNotSaveToStorage(documentCi);
         const documentCiExtension = getFileExtension(documentCiToPost);
         formData.append(`files[${id}][${indexOfId + 1}]`, documentCiToPost, `act_identitate.${documentCiExtension}`);
      } else {
         const documentCuiToPost = await verifyToNotSaveToStorage(documentCui);
         const documentCuiExtension = getFileExtension(documentCuiToPost);
         formData.append(`files[${id}][${indexOfId + 2}]`, documentCuiToPost, `cui.${documentCuiExtension}`);
      }
   };

   let promises = [];
   switch (utility_type) {
      case "GAZ_SI_CURENT":
         promises.push(appendToFormData("gaz", gasId, values.invoice_gas, values.document_ci, values.document_cui));
         promises.push(appendToFormData("energie", energyId, values.invoice_energy, values.document_ci, values.document_cui));
         break;
      case "GAZ":
         promises.push(appendToFormData("gaz", gasId, values.invoice_gas, values.document_ci, values.document_cui));
         break;
      case "CURENT":
         promises.push(appendToFormData("energie", energyId, values.invoice_energy, values.document_ci, values.document_cui));
         break;
      default:
         break;
   }
   await Promise.all(promises);

   return formData;
};

const getPdfs = (utility_type, lead_object, consumer_type) => {
   const result = [];

   if (utility_type === "GAZ_SI_CURENT") {
      result.push({
         utility_type: "energy",
         consumer_type: consumer_type,
         lead_id: lead_object?.energy?.id,
      });
      result.push({
         utility_type: "gas",
         consumer_type: consumer_type,
         lead_id: lead_object?.gas?.id,
      });
   }
   if (utility_type === "GAZ") {
      result.push({
         utility_type: "gas",
         consumer_type: consumer_type,
         lead_id: lead_object?.gas?.id,
      });
   }
   if (utility_type === "CURENT") {
      result.push({
         utility_type: "energy",
         consumer_type: consumer_type,
         lead_id: lead_object?.energy?.id,
      });
   }

   return result;
};

const signature = values => {
   const leads = [];
   if (values.lead_object?.energy?.id) leads.push(values.lead_object.energy.id);
   if (values.lead_object?.gas?.id) leads.push(values.lead_object.gas.id);

   return {
      file: values.signature,
      leads,
   };
};

const mailOffers = (data, stepsState) => {
   const tenant_assocToPost = stepsState.tenant_assoc === "0" ? 0 : 1;
   let consumEnergy = stepsState.monthly_consumption_energy !== "" ? stepsState.monthly_consumption_energy : stepsState.estimated_energy;
   let consumGas = stepsState.monthly_consumption_gas !== "" ? stepsState.monthly_consumption_gas : stepsState.estimated_gas;

   if (stepsState.utility_type === "GAZ_SI_CURENT") {
      return [
         {
            utility_type: "energy",
            consumer_type: stepsState.consumer_type,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            county_id: data.county_id,
            city_id: data.city_id,
            consumption: parseInt(transformForPJInKWh(consumEnergy, stepsState.consumer_type === "pj")),
            ...(stepsState.consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
            gdpr_consent: data.gdpr_consent ? 1 : 0,
            marketing_consent: data.marketing_consent ? 1 : 0,
         },
         {
            utility_type: "gas",
            consumer_type: stepsState.consumer_type,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            county_id: data.county_id,
            city_id: data.city_id,
            consumption: parseInt(transformForPJInKWh(consumGas, stepsState.consumer_type === "pj")),
            ...(stepsState.consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
            gdpr_consent: data.gdpr_consent ? 1 : 0,
            marketing_consent: data.marketing_consent ? 1 : 0,
         },
      ];
   } else {
      return [
         {
            utility_type: stepsState.utility_type === "GAZ" ? "gas" : "energy",
            consumer_type: stepsState.consumer_type,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            county_id: data.county_id,
            city_id: data.city_id,
            consumption:
               data.utility_type === "GAZ"
                  ? parseInt(transformForPJInKWh(consumGas, stepsState.consumer_type === "pj"))
                  : parseInt(transformForPJInKWh(consumEnergy, stepsState.consumer_type === "pj")),
            ...(stepsState.consumer_type === "pf" ? { tenant_assoc: tenant_assocToPost } : {}),
            gdpr_consent: data.gdpr_consent ? 1 : 0,
            marketing_consent: data.marketing_consent ? 1 : 0,
         },
      ];
   }
};
const prepareValuesLead = (values, forState = false, consumer_type) => {
   console.log("🚀 ~ prepareValuesLead ~ values:", values);
   console.log("🚀 ~ prepareValuesLead ~ forState:", forState);

   const unitMeasures = {
      unitMeasureGas: forState ? values.unitMeasureGas : "kwh",
      unitMeasureEnergy: forState ? values.unitMeasureEnergy : "kwh",
   };
   const transformConsumptionAgreementInKWh = (consumptionAgreement = {}, isInMWh) => {
      let consumptionAgreementInkWh = {};

      Object.keys(consumptionAgreement).forEach(key => {
         let value = Number(consumptionAgreement?.[key]);
         consumptionAgreementInkWh[key] = String(isInMWh ? value * 1000 : value);
      });
      return consumptionAgreementInkWh;
   };

   const isPJ = consumer_type === "pj";

   const valuesEnergyInKWh = transformConsumptionAgreementInKWh(values?.consumption_agreement_energy, isPJ);
   const valuesGasInKWh = transformConsumptionAgreementInKWh(values?.consumption_agreement_gas, isPJ);

   const result = {
      ...values,
      isInit: true,
      ...unitMeasures,
      consumption_agreement_energy: forState ? values?.consumption_agreement_energy : valuesEnergyInKWh,
      consumption_agreement_gas: forState ? values?.consumption_agreement_gas : valuesGasInKWh,
      locations: [
         ...values.locations.map(location => {
            const valuesEnergyInKWh = transformConsumptionAgreementInKWh(location?.consumption_agreement_energy, isPJ);
            const valuesGasInKWh = transformConsumptionAgreementInKWh(location?.consumption_agreement_gas, isPJ);

            return {
               ...location,
               isInit: true,
               consumption_agreement_energy: forState ? values?.consumption_agreement_energy : valuesEnergyInKWh,
               consumption_agreement_gas: forState ? values?.consumption_agreement_gas : valuesGasInKWh,
            };
         }),
      ],
   };

   return result;
};

const submitTicket = values => {
   console.log("🚀 ~ submitTicket ~ values:", values);
   console.log("🚀 ~ submitTicket ~ values.utility_type:", values.utility_type);
   let specificData = {};
   if (values.consumer_type === "pf" && values.tenant_assoc === "0") {
      specificData = {
         ci_cnp: values.ci_cnp,
         ci_sn: values.ci_sn,
         ci_number: values.ci_number,
         ci_issuer: values.ci_issuer,
         ci_date: format(new Date(values.ci_date), "yyyy-MM-dd"),
      };
   } else {
      specificData = {
         company_name: values.company_name,
         company_cif: values.company_cif,
      };
   }
   return {
      consumer_type: values.consumer_type,
      tenant_assoc: values.tenant_assoc,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone_number,
      county_id: values.county_id,
      city_id: values.city_id,
      description: values.description,
      consumption_energy: values.utility_type === "CURENT" || values.utility_type === "GAZ_SI_CURENT" ? values.monthly_consumption_energy : null,

      consumption_gas: values.utility_type === "GAZ" || values.utility_type === "GAZ_SI_CURENT" ? values.monthly_consumption_gas : null,
      ...specificData,
   };
};

const prepareData = {
   step2Offers,
   step4Offers,
   getProviders,
   upload,
   getPdfs,
   signature,
   mailOffers,
   prepareLeadNew,
   prepareValuesLead,
   submitTicket,
   transformForPJInKWh,
};

export default prepareData;
