import React from "react";
import TextInput from "components/inputs/TextInput";

export default function SectionIndex({ formik, utility_type, isInArray = false, arrayIndex }) {
   return (
      <>
         <h2 className="custom-label-title">Introducere Index</h2>
         {(utility_type === "CURENT" || utility_type === "GAZ_SI_CURENT") && (
            <TextInput
               label={"Index electricitate"}
               name={isInArray ? `locations.${arrayIndex}.index_energy` : "index_energy"}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.index_energy : formik.values.index_energy}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.index_energy : formik.errors.index_energy}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.index_energy : formik.touched.index_energy}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-12 mb-25"}
               allowNumbersAndDots={true}
            />
         )}
         {(utility_type === "GAZ" || utility_type === "GAZ_SI_CURENT") && (
            <TextInput
               label={"Index gaz"}
               name={isInArray ? `locations.${arrayIndex}.index_gas` : "index_gas"}
               value={isInArray ? formik.values.locations?.[arrayIndex]?.index_gas : formik.values.index_gas}
               error={isInArray ? formik.errors.locations?.[arrayIndex]?.index_gas : formik.errors.index_gas}
               touched={isInArray ? formik.touched.locations?.[arrayIndex]?.index_gas : formik.touched.index_gas}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-12 mb-25"}
               allowNumbersAndDots={true}
            />
         )}
         {/* todo prosumer info deactivated for now */}
         {/*{((!isInArray && formik.values.isProsumer) || (isInArray && formik.values.locations[arrayIndex].isProsumer)) && (*/}
         {/*   <>*/}
         {/*      <RadioRow*/}
         {/*         value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_contract_type : formik.values.prosumer_contract_type}*/}
         {/*         name={isInArray ? `locations.${arrayIndex}.prosumer_contract_type` : "prosumer_contract_type"}*/}
         {/*         onChange={formik.handleChange}*/}
         {/*         title={"Tip Contract"}*/}
         {/*         options={objectsSchema.contract_type_radio}*/}
         {/*         titleClassName={"col-md-3"}*/}
         {/*         labelClassName={"col-md-3"}*/}
         {/*      />*/}
         {/*      <RadioRow*/}
         {/*         value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_voltage : formik.values.prosumer_voltage}*/}
         {/*         name={isInArray ? `locations.${arrayIndex}.prosumer_voltage` : "prosumer_voltage"}*/}
         {/*         onChange={formik.handleChange}*/}
         {/*         title={"Nivel Tensiune"}*/}
         {/*         options={objectsSchema.voltage_radio}*/}
         {/*         titleClassName={"col-md-3"}*/}
         {/*         labelClassName={"col-md-3"}*/}
         {/*      />*/}
         {/*      <div className="row mb-25">*/}
         {/*         <TextInputRow*/}
         {/*            label={"IBAN"}*/}
         {/*            name={isInArray ? `locations.${arrayIndex}.prosumer_iban` : "prosumer_iban"}*/}
         {/*            value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_iban : formik.values.prosumer_iban}*/}
         {/*            error={isInArray ? formik.errors.locations?.[arrayIndex]?.prosumer_iban : formik.errors.prosumer_iban}*/}
         {/*            touched={isInArray ? formik.touched.locations?.[arrayIndex]?.prosumer_iban : formik.touched.prosumer_iban}*/}
         {/*            setFieldValue={formik.setFieldValue}*/}
         {/*            parentClassName={"col-md-6 input-row"}*/}
         {/*            labelClassNames={"col-4"}*/}
         {/*            inputClassNames={"col-8"}*/}
         {/*         />*/}
         {/*         <TextInputRow*/}
         {/*            label={"Banca"}*/}
         {/*            name={isInArray ? `locations.${arrayIndex}.prosumer_bank` : "prosumer_bank"}*/}
         {/*            value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_bank : formik.values.prosumer_bank}*/}
         {/*            error={isInArray ? formik.errors.locations?.[arrayIndex]?.prosumer_bank : formik.errors.prosumer_bank}*/}
         {/*            touched={isInArray ? formik.touched.locations?.[arrayIndex]?.prosumer_bank : formik.touched.prosumer_bank}*/}
         {/*            setFieldValue={formik.setFieldValue}*/}
         {/*            parentClassName={"col-md-6"}*/}
         {/*            labelClassNames={"col-4"}*/}
         {/*            inputClassNames={"col-8"}*/}
         {/*         />*/}
         {/*      </div>*/}
         {/*      <div className="row mb-25">*/}
         {/*         <TextInputRow*/}
         {/*            label={"P max"}*/}
         {/*            name={isInArray ? `locations.${arrayIndex}.prosumer_pmax` : "prosumer_pmax"}*/}
         {/*            value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_pmax : formik.values.prosumer_pmax}*/}
         {/*            error={isInArray ? formik.errors.locations?.[arrayIndex]?.prosumer_pmax : formik.errors.prosumer_pmax}*/}
         {/*            touched={isInArray ? formik.touched.locations?.[arrayIndex]?.prosumer_pmax : formik.touched.prosumer_pmax}*/}
         {/*            setFieldValue={formik.setFieldValue}*/}
         {/*            parentClassName={"col-md-6 input-row"}*/}
         {/*            labelClassNames={"col-4"}*/}
         {/*            inputClassNames={"col-8"}*/}
         {/*         />*/}
         {/*         <TextInputRow*/}
         {/*            label={"Nr. CR"}*/}
         {/*            name={isInArray ? `locations.${arrayIndex}.prosumer_nrCr` : "prosumer_nrCr"}*/}
         {/*            value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_nrCr : formik.values.prosumer_nrCr}*/}
         {/*            error={isInArray ? formik.errors.locations?.[arrayIndex]?.prosumer_nrCr : formik.errors.prosumer_nrCr}*/}
         {/*            touched={isInArray ? formik.touched.locations?.[arrayIndex]?.prosumer_nrCr : formik.touched.prosumer_nrCr}*/}
         {/*            setFieldValue={formik.setFieldValue}*/}
         {/*            parentClassName={"col-md-6"}*/}
         {/*            labelClassNames={"col-4"}*/}
         {/*            inputClassNames={"col-8"}*/}
         {/*         />*/}
         {/*      </div>*/}
         {/*      <div className="row mb-25">*/}
         {/*         <TextInputRow*/}
         {/*            label={"CR emis de"}*/}
         {/*            name={isInArray ? `locations.${arrayIndex}.prosumer_crIssuer` : "prosumer_crIssuer"}*/}
         {/*            value={isInArray ? formik.values.locations?.[arrayIndex]?.prosumer_crIssuer : formik.values.prosumer_crIssuer}*/}
         {/*            error={isInArray ? formik.errors.locations?.[arrayIndex]?.prosumer_crIssuer : formik.errors.prosumer_crIssuer}*/}
         {/*            touched={isInArray ? formik.touched.locations?.[arrayIndex]?.prosumer_crIssuer : formik.touched.prosumer_crIssuer}*/}
         {/*            setFieldValue={formik.setFieldValue}*/}
         {/*            parentClassName={"col-md-6 input-row"}*/}
         {/*            labelClassNames={"col-4"}*/}
         {/*            inputClassNames={"col-8"}*/}
         {/*         />*/}
         {/*         <ConventieProductie formik={formik} classNames={"col-md-6"} isInArray={isInArray} arrayIndex={arrayIndex} />*/}
         {/*      </div>*/}
         {/*   </>*/}
         {/*)}*/}
      </>
   );
}
