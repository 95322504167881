import React, { useState } from "react";
import "assets/css/font-icomoon.css";

export default function ServiciiRadio({ value, name, setFieldValue, error, touched }) {
   const [serviciu, setServiciu] = useState(null);

   const getServiciiCardClassName = (serviciu, selected) => {
      if (serviciu === null && value === selected) return "col-sm-12 col-md-12 col-lg-4 servicii-card servicii-card-selected";
      if (selected === serviciu) return "col-sm-12 col-md-12 col-lg-4 servicii-card servicii-card-selected";
      else return "col-sm-12 col-md-12 col-lg-4 servicii-card";
   };

   const handleClick = serviciu => {
      setServiciu(serviciu);
      setFieldValue(name, serviciu);
      if (serviciu === "GAZ") setFieldValue("voltage", "");
   };
   return (
      <>
         <div id={name} className="mx-auto row servicii-container">
            <label className="custom-label-title">Ce servicii te interesează?</label>
            <div
               id="gaz_electric_selector"
               onClick={() => handleClick("GAZ_SI_CURENT")}
               className={getServiciiCardClassName(serviciu, "GAZ_SI_CURENT")}
            >
               <div className="servicii-two-icons">
                  <div className="servicii-gaz-img" />
                  <div className="servicii-curent-img" />
               </div>
               <p className="servicii-card-title">Energie electrică + Gaze naturale</p>
               <div className="servicii-card-button">
                  <p>Selecteaza</p>
               </div>
            </div>

            <div id="electricity_selector" onClick={() => handleClick("CURENT")} className={getServiciiCardClassName(serviciu, "CURENT")}>
               <div className="servicii-one-icon">
                  <div className="servicii-curent-img" />
               </div>
               <p className="servicii-card-title">Energie Electrica</p>
               <div className="servicii-card-button">
                  <p>Selecteaza</p>
               </div>
            </div>

            <div id="gaz_selector" onClick={() => handleClick("GAZ")} className={getServiciiCardClassName(serviciu, "GAZ")}>
               <div className="servicii-one-icon">
                  <div className="servicii-gaz-img" />
               </div>
               <p className="servicii-card-title">Gaz</p>
               <div className="servicii-card-button">
                  <p>Selecteaza</p>
               </div>
            </div>
         </div>
         {error && touched && <p className="error-msg">{error}</p>}
      </>
   );
}
