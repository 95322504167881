import { requestJson, RequestMethod } from "helpers/httpHelper";
import prepareData from "schemas/prepareDataSchema";
import { mockContracts } from "../mock-data/documents";
import { isArray, isObject } from "lodash";
const getOffers = async (values, step) => {
   const dataToPost = step === "STEP2" ? prepareData.step2Offers(values) : prepareData.step4Offers(values);
   console.log("🚀 ~ getOffers ~ dataToPost:", dataToPost);
   const response = await requestJson("price/all", RequestMethod.POST, {
      data: dataToPost,
   });
   return response;
};

const createUpdateLeadNew = async (values, tenantAssoc, selectedOffers, estimated_energy, estimated_gas, provider_id_energy, provider_id_gas) => {
   const response = await requestJson("lead", RequestMethod.POST, {
      data: prepareData.prepareLeadNew(values, tenantAssoc, selectedOffers, estimated_energy, estimated_gas, provider_id_energy, provider_id_gas),
   });
   console.log("🚀 ~ createUpdateLeadNew ~ response:", response);
   return response;
};

const upload = async values => {
   const response = await requestJson("file", RequestMethod.POST, {
      data: await prepareData.upload(values),
   });
   return response;
};

const getPdfs = async (utility_type, lead_object, consumer_type) => {
   const response = await requestJson(
      "file/documents",
      RequestMethod.POST,
      {
         data: prepareData.getPdfs(utility_type, lead_object, consumer_type),
      },
      true,
   );
   return response;
};
let countSimulatedFailAttepts = 30;

const getPdfsMocked = async (utility_type, lead_object, consumer_type) => {
   const response = {
      isSuccess: true,
      response: mockContracts,
   };

   if (countSimulatedFailAttepts >= 0) {
      // await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 6 seconds
      countSimulatedFailAttepts--;

      return {
         isSuccess: false,
      };
   }

   if (countSimulatedFailAttepts <= 0) {
      countSimulatedFailAttepts = 30;
      return response;
   }
   return {
      isSuccess: false,
   };
};
let count = 0;
async function fetchPdfsWithRetry(maxRetries, requestPayload, actions) {
   if (!requestPayload) {
      console.warn("Cannot find requestPayload in fetchPdfsWithRetry");
      return;
   }

   const { utility_type, lead_object, consumer_type } = requestPayload;
   // const responsePdf = await getPdfsMocked();
   const responsePdf = await apiSteps.getPdfs(utility_type, lead_object, consumer_type);
   if (responsePdf?.isSuccess && isObject(responsePdf.response) && !isArray(responsePdf.response)) {
      actions?.actionsForSuccess(responsePdf.response);
      count = 0;
   } else {
      if (count < maxRetries) {
         count++;
         await new Promise(resolve => setTimeout(resolve, 5000)); // Wait for 6 seconds

         await fetchPdfsWithRetry(maxRetries, requestPayload, actions); // Retry the getPdfs request
      } else {
         count = 0;
         actions?.actionsForFail();
      }
   }
}

const signature = async values => {
   const response = await requestJson("file/signature", RequestMethod.POST, {
      data: prepareData.signature(values),
   });
   return response;
};

const submitTicket = async values => {
   const data = prepareData.submitTicket(values);
   console.log("🚀 ~ submitTicket ~ data:", data);

   const response = await requestJson("lead/openTicket", RequestMethod.POST, {
      data,
   });
   return response;
};

export const apiSteps = {
   getOffers,
   // createUpdateLead,
   upload,
   getPdfs,
   signature,
   createUpdateLeadNew,
   getPdfsMocked,
   fetchPdfsWithRetry,
   submitTicket,
};
