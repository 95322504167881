import React, { useState, useRef } from "react";
import { displayCorrectContent, getSizeOfUpload } from "helpers/helpers";
import { pdfjs } from "react-pdf";
import { toast } from "react-toastify";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function UploadFile({ label, value, name, error, touched, setFieldValue, description, accept, classNames, maxFileSize = null }) {
   const uploadRef = useRef(null);
   const uploadedValue = displayCorrectContent(value);
   const [uploadFileName, setUploadFileName] = useState(null);
   const fileNameToDisplay = (uploadFileName || value?.name) ?? "Selectează fișierul";

   const reset = () => {
      uploadRef.current.value = "";
   };

   return (
      <>
         <div id={name} className={`${classNames} text-center`}>
            <label id={name} className="cursor-pointer text-center w-100">
               <div className={`upload-file-new-box ${error ? "error-box" : ""}`}>
                  <div className={`upload-file-new-icon-container ${uploadedValue.imageToDisplay ? "background-green-icomoon-d" : ""}`}>
                     <div className={`upload-file-new-icon-icomoon-d`} />
                  </div>
                  <div className="upload-file-new-box-content">
                     <h2 className={`upload-file-new-box-title ${uploadedValue.imageToDisplay ? "text-green" : ""}`}>{label}</h2>
                     <h2 className="upload-file-new-box-description">{description}</h2>
                     <h2 className="upload-file-new-box-fileName">{fileNameToDisplay}</h2>
                     {maxFileSize && !value && (
                        <h2 className="upload-file-new-box-fileName">{`*Fisierul trebuie sa aiba o dimensiune maxima de ${maxFileSize} MB`}</h2>
                     )}
                  </div>
               </div>
               <input
                  id={name}
                  onChange={async event => {
                     let uploadedFile = event.currentTarget.files[0];

                     const acceptedTypes = accept.split(",").map(type => type.trim());
                     if (!acceptedTypes.includes(uploadedFile.type)) {
                        toast.error("Tipul de fisier nu este acceptat");
                        return;
                     }

                     const fileSizeInMB = await getSizeOfUpload(uploadedFile);
                     if (maxFileSize && fileSizeInMB > maxFileSize) {
                        toast.error(`Fisierul nu poate fi mai mare de ${maxFileSize} MB`);
                        return;
                     }

                     setUploadFileName(uploadedFile.name);
                     // let fileToPersistOrNot = null;
                     // if (fileSizeInMB > 1) fileToPersistOrNot = await verifyToNotSaveToStorage(uploadedFile);
                     // else fileToPersistOrNot = await verifyToStorage(uploadedFile);

                     // setFieldValue(name, fileToPersistOrNot);
                     setFieldValue(name, uploadedFile);

                     reset();
                  }}
                  type="file"
                  accept={accept}
                  className="hidden"
                  ref={uploadRef}
               />
            </label>
         </div>
         {/* <div className="text-center">
            {uploadedValue.imageToDisplay !== null && uploadedValue.imageToDisplay !== undefined && (
               <button type="button" className="delete-button" onClick={() => handleRemoveImage()}>
                  Sterge
               </button>
            )}

            {uploadedValue.isPdf ? (
               <Document file={uploadedValue.imageToDisplay}>
                  <Page pageNumber={1} />
               </Document>
            ) : (
               <img className="ss-prew" src={uploadedValue.imageToDisplay} />
            )}
         </div> */}
         {error && touched && <p className="error-msg mb-25">{error}</p>}
      </>
   );
}
