import { isObject } from "lodash";

export const goToTop = () => {
   document.getElementById("top").scrollIntoView({ behavior: "smooth", block: "start" });
};

export const refToErrorFormik = formikErrors => {
   if (!formikErrors) return;
   if (Object.keys(formikErrors).length > 0) {
      if (isObject(formikErrors[Object.keys(formikErrors)[0]])) {
         const objectArrayName = Object.keys(formikErrors)[0];
         const objectArrayIndex = Object.keys(formikErrors[Object.keys(formikErrors)[0]])[0];
         const objectArrayKey = Object.keys(
            formikErrors[Object.keys(formikErrors)[0]][Object.keys(formikErrors[Object.keys(formikErrors)[0]])[0]],
         )[0];
         const objectArrayJoin = `${objectArrayName}[${objectArrayIndex}].${objectArrayKey}`;
         document.getElementById(objectArrayJoin)?.scrollIntoView({ behavior: "smooth", block: "center" });
         document.getElementById(objectArrayJoin)?.focus();
      } else {
         document.getElementById(Object.keys(formikErrors)[0])?.scrollIntoView({ behavior: "smooth", block: "center" });
         document.getElementById(Object.keys(formikErrors)[0])?.focus();
      }
   }
};

export const fileToBase64 = file => {
   return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
   });
};

export const base64ToFile = (dataurl, filename) => {
   var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

   while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
   }

   return new File([u8arr], filename, { type: mime });
};

export const base64ToFilePdf = data => {
   // const base64WithoutPrefix = data.substr("data:application/pdf;base64,".length);
   const bytes = atob(data);
   let length = bytes.length;
   let out = new Uint8Array(length);

   while (length--) {
      out[length] = bytes.charCodeAt(length);
   }

   return new Blob([out], { type: "application/pdf" });
};

export const verifyToStorage = async data => {
   if (data instanceof File) return await fileToBase64(data);
   return data;
};

export const verifyToNotSaveToStorage = async data => {
   if (data instanceof File) return data;
   else return base64ToFile(data, "file");
};

export const removeDiacritics = str => {
   return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toUpperCase();
};

export const checkIfEmptyObject = value => {
   if (value === null || value === undefined) return true;
   if (typeof value === "object" && Object.keys(value).length === 0 && !(value instanceof File)) return true;
   else return false;
};

export const getSizeOfUpload = async uploadedFile => {
   // Convert base64 string to Blob
   let base64StringFromFile = uploadedFile;
   if (base64StringFromFile instanceof File) {
      base64StringFromFile = await fileToBase64(uploadedFile);
   }
   const byteCharacters = atob(base64StringFromFile.split(",")[1]);
   const byteArrays = [];
   for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
   }
   const blob = new Blob([new Uint8Array(byteArrays)]);

   // Get file size in bytes
   const fileSizeInBytes = blob.size;

   // Convert file size to megabytes (MB)
   const fileSizeInMb = fileSizeInBytes / (1024 * 1024);

   return fileSizeInMb;
};

export const displayCorrectContent = value => {
   if (value === null || value === undefined || (typeof value === "object" && Object.keys(value).length === 0 && !(value instanceof File)))
      return { isPdf: false, imageToDisplay: null };
   if (value instanceof File && value.type === "application/pdf") {
      return { isPdf: true, imageToDisplay: value };
   } else if (typeof value !== "string") {
      return { isPdf: false, imageToDisplay: URL.createObjectURL(value) };
   } else if (value.split(";")[0] === "data:application/pdf") {
      return { isPdf: true, imageToDisplay: value };
   } else {
      return { isPdf: false, imageToDisplay: value };
   }
};

export const getNumberFromString = str => {
   const number = parseFloat(str);
   return isNaN(number) ? 0 : number;
};

export const getFileExtension = file => {
   const fileType = file.type;
   return fileType.split("/")[1];
};

export const checkIfMultipleUploadHasValue = value => {
   if (!value) return false;
   if (Array.isArray(value)) if (checkIfEmptyObject(value[0])) return false;

   return true;
};
