import React from "react";
import { useFormik } from "formik";
import { step6ValidationSchema } from "schemas/validationSchema";
import { goToTop } from "helpers/helpers";
import { apiSteps } from "api/steps.service";
import { toast } from "react-toastify";
import UploadFile from "components/inputs/UploadFile";
import UploadFileMultiple from "components/inputs/UploadFileMultiple";
import NavigateButtons from "components/NavigateButtons";
import { useFetchPdfsWithRetry } from "helpers/hooks";
export default function Step6({ currentStep, setCurrentStep, stepsState, persistState, setLoading, firstSubmit, setFirstSubmit }) {
   const formik = useFormik({
      enableReinitialize: true,
      initialValues: stepsState.step6,
      validateOnChange: firstSubmit,
      validateOnBlur: false,
      validationSchema: step6ValidationSchema,
      onSubmit: async (value, { setSubmitting }) => {
         goToTop();
         setLoading(true);
         await saveData(value);
         setLoading(false);
         setSubmitting(false);
      },
   });
   const actions = {
      actionsForFail: () => {},
      actionsForSuccess: response => {
         console.log("🚀 ~ Step6 ~ response:", response);

         persistState(prevState => {
            return {
               ...prevState,
               step7: {
                  ...prevState.step7,
                  pdfs: response,
               },
            };
         });
      },
   };

   useFetchPdfsWithRetry(stepsState, actions);
   const goToNextStep = () => {
      setCurrentStep(currentStep + 1);
      sessionStorage.setItem("sessionCurrentStep", currentStep + 1);
      setFirstSubmit(false);
   };
   const saveData = async values => {
      const response = await apiSteps.upload({
         ...values,
         utility_type: stepsState?.utility_type,
         consumer_type: stepsState?.consumer_type,
         lead_object: stepsState?.lead_object,
      });

      if (response.isSuccess) {
         persistState(prevState => {
            return {
               ...prevState,
               step6: {
                  ...prevState.step6,
                  ...values,
               },
            };
         });
         goToNextStep();
      } else {
         toast.error("Nu am reusit sa incarcam imaginea.Va rugam sa incercati din nou!");
         setLoading(false);
      }
   };

   const formSubmition = e => {
      e.preventDefault();
      setFirstSubmit(true);
      formik.handleSubmit();
   };

   return (
      <section className="pb-55">
         <div className="container">
            <div className="col-md-9 mx-auto">
               <h2 className="step-title">Încarcă documentele</h2>
               <h3 className="step-description col-md-9 mx-auto">
                  Aproape ai terminat! Încarcă documentele indicate mai jos. Poți selecta fișierele din computer sau poți fotografia documentele
                  originale dacă ești pe mobil.
               </h3>
               <form onSubmit={formSubmition}>
                  {stepsState?.consumer_type === "pf" && stepsState?.tenant_assoc === "0" && (
                     <UploadFile
                        name={"document_ci"}
                        value={formik.values.document_ci}
                        error={formik.errors.document_ci}
                        touched={formik.touched.document_ci}
                        setFieldValue={formik.setFieldValue}
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        label={"Act de identitate titular contract*"}
                        description={""}
                        maxFileSize={5}
                     />
                  )}
                  {(stepsState?.consumer_type === "pj" || stepsState?.tenant_assoc === "1") && (
                     <UploadFile
                        name={"document_cui"}
                        value={formik.values.document_cui}
                        error={formik.errors.document_cui}
                        touched={formik.touched.document_cui}
                        setFieldValue={formik.setFieldValue}
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        label={"Copie CIF/CUI"}
                        description={""}
                        maxFileSize={5}
                     />
                  )}
                  {(stepsState?.utility_type === "GAZ_SI_CURENT" || stepsState?.utility_type === "CURENT") && (
                     <UploadFileMultiple
                        name={"invoice_energy"}
                        value={formik.values.invoice_energy}
                        error={formik.errors.invoice_energy}
                        touched={formik.touched.invoice_energy}
                        setFieldValue={formik.setFieldValue}
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        label={"Recomandăm încărcarea unei facturi de energie electrică"}
                        description={"O factură completă de la furnizorul actual (toate paginile)."}
                        maxFileSize={5}
                     />
                  )}
                  {(stepsState?.utility_type === "GAZ_SI_CURENT" || stepsState?.utility_type === "GAZ") && (
                     <UploadFileMultiple
                        name={"invoice_gas"}
                        value={formik.values.invoice_gas}
                        error={formik.errors.invoice_gas}
                        touched={formik.touched.invoice_gas}
                        setFieldValue={formik.setFieldValue}
                        accept={"image/png, image/jpg, image/jpeg, application/pdf"}
                        label={"Recomandăm încărcarea unei facturi de gaze naturale"}
                        description={"O factură completă de la furnizorul actual (toate paginile)."}
                        maxFileSize={5}
                     />
                  )}

                  <NavigateButtons
                     isNextDisabled={formik.isSubmitting}
                     persistState={persistState}
                     currentStep={currentStep}
                     setCurrentStep={setCurrentStep}
                  />
               </form>
            </div>
         </div>
      </section>
   );
}
