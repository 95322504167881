import React, { useEffect, useState } from "react";
import { apiNomenclature } from "api/nomenclature.service";
import TextInput from "components/inputs/TextInput";
import CustomSelect from "components/inputs/CustomSelect";

export default function SectionDateDeContact({ formik, consumer_type, tenant_assoc, isUsedInOpenTicketModal }) {
   const [optionsBanks, setOptionsBanks] = useState([]);

   useEffect(() => {
      const getOptionsBanks = async () => {
         const response = await apiNomenclature.getBanks();
         setOptionsBanks(
            response.data.map(item => {
               return { value: item.company_bank_id, label: item.company_bank };
            }),
         );
      };

      getOptionsBanks();
   }, []);

   return (
      <>
         <h2 className="custom-label-title">Date de contact</h2>
         <div className="row mb-25">
            <TextInput
               name={"email"}
               label={"Email"}
               value={formik.values.email}
               error={formik.errors.email}
               touched={formik.touched.email}
               isDisabled={isUsedInOpenTicketModal}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-6 input-row"}
            />
            <TextInput
               name={"phone_number"}
               label={"Telefon"}
               value={formik.values.phone_number}
               error={formik.errors.phone_number}
               touched={formik.touched.phone_number}
               setFieldValue={formik.setFieldValue}
               parentClassName={"col-md-6"}
               isDisabled={isUsedInOpenTicketModal}
            />
         </div>
         {(consumer_type === "pj" || tenant_assoc === "1") && (
            <>
               <div className="row mb-25">
                  <TextInput
                     name={"company_name"}
                     label={"Nume firma"}
                     value={formik.values.company_name}
                     error={formik.errors.company_name}
                     touched={formik.touched.company_name}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-6 input-row"}
                     isDisabled={isUsedInOpenTicketModal}
                  />
                  <TextInput
                     name={"company_cif"}
                     label={"CUI/CIF"}
                     value={formik.values.company_cif}
                     error={formik.errors.company_cif}
                     touched={formik.touched.company_cif}
                     setFieldValue={formik.setFieldValue}
                     parentClassName={"col-md-6"}
                     isDisabled={isUsedInOpenTicketModal}
                  />
               </div>
               {!isUsedInOpenTicketModal && (
                  <div className="row mb-25">
                     <TextInput
                        name={"company_bank_account"}
                        label={"Cont bancar"}
                        value={formik.values.company_bank_account}
                        error={formik.errors.company_bank_account}
                        touched={formik.touched.company_bank_account}
                        setFieldValue={formik.setFieldValue}
                        parentClassName={"col-md-6 input-row"}
                        isDisabled={isUsedInOpenTicketModal}
                     />
                     <CustomSelect
                        options={optionsBanks}
                        value={formik.values.company_bank_id}
                        onChange={value => formik.setFieldValue("company_bank_id", value.value)}
                        placeholder="Alege banca..."
                        name={"company_bank_id"}
                        label={"Banca"}
                        error={formik.errors.company_bank_id}
                        touched={formik.touched.company_bank_id}
                        parentClassName={"col-md-6 relative input-row"}
                        labelClassName={"label-select"}
                     />
                  </div>
               )}
            </>
         )}
      </>
   );
}
