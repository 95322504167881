import React from "react";
import TextInput from "components/inputs/TextInput";

export default function OfertaMail({ formikValues, formikErrors, formikTouched, setFieldValue }) {
   return (
      <div className="row mb-40">
         <TextInput
            name={"first_name"}
            label={"Prenume"}
            value={formikValues.first_name}
            error={formikErrors.first_name}
            touched={formikTouched.first_name}
            setFieldValue={setFieldValue}
            parentClassName={"col-md-4 pe-2"}
         />
         <TextInput
            name={"last_name"}
            label={"Nume"}
            value={formikValues.last_name}
            error={formikErrors.last_name}
            touched={formikTouched.last_name}
            setFieldValue={setFieldValue}
            parentClassName={"col-md-4 pe-2"}
         />
         <TextInput
            name={"email"}
            label={"Email"}
            value={formikValues.email}
            error={formikErrors.email}
            touched={formikTouched.email}
            setFieldValue={setFieldValue}
            parentClassName={"col-md-4 ps-2"}
         />
      </div>
   );
}
